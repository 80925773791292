import Disclosure from 'components/Disclosure';
import { DocumentModuleGroup } from 'containers/Dashboard/Document/modules/modules';
import { Box, Col, Text } from '@compoundfinance/design-system';

interface DisclaimerProps {
  documentType: string;
}

function Disclaimer(props: DisclaimerProps) {
  const { documentType } = props;

  switch (documentType) {
    case DocumentModuleGroup.MembershipProposal:
      return (
        <Col css={{ gap: '$6', mt: '$4', w: '1034px' }}>
          <Text size="13" color="gray11" weight="medium">
            Disclaimer
          </Text>
          <Text size="13" color="gray10" css={{ lineHeight: '20px' }}>
            Subject to the terms and conditions of the{' '}
            <a
              href="https://legal.compoundplanning.com/client-agreements"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'underline' }}
            >
              Compound Client Agreements
            </a>
            . Compound Financial Inc. (“Compound Financial”) offers
            software-based financial management and planning tools. Investment
            advisory services are provided by Compound Advisors, Inc. (“Compound
            Advisers”), an SEC-registered investment adviser. Compound Tax, LLC
            (“Compound Tax”) provides tax consulting and compliance services.
            Compound Advisers and Compound Tax are wholly owned subsidiaries of
            Compound Financial. Altogether, we refer to our business as
            (“Compound”). Registration as an investment adviser does not imply
            any level of skill or training. Nothing on this document should be
            considered an offer, solicitation of an offer, or advice to buy or
            sell securities. Compound is not a licensed lender, law firm or
            insurance agency, and any such services mentioned in this document
            are provided by non-affiliated referrers. Account holdings are for
            illustrative purposes only and are not investment recommendations.
            The content on this document is for informational purposes only and
            does not constitute a comprehensive description of Compound’s
            investment advisory services. Refer to Compound’s Brochure (
            <a
              href="https://compoundplanning.com/legal/adv"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'underline' }}
            >
              Form ADV Part 2A
            </a>
            ) and Client Relationship Summary (
            <a
              href="https://compoundplanning.com/legal/crs"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'underline' }}
            >
              Form CRS
            </a>
            ) for more information. Brokerage services are provided by Charles
            Schwab & Co., Inc. (“Schwab,” Member SIPC). Access to alternative
            investment deals is available for accredited investors or qualified
            purchasers only.
            <br />
            <br />
            We calculate your fee on a quarterly basis. If your billable assets
            directly managed by Compound do not generate at least $1,625 in a
            quarter, we will bill you $1,625 for that quarter. If your average
            billable and managed assets are $1M or greater in a quarter, we will
            bill you based on the standard AUM schedule. Third party
            sub-advisor-fees may apply outside of our membership fee. “Marginal
            AUM fee” means that the next dollar that falls within the next
            higher value AUM bracket is charged at that new rate in the next
            higher value AUM bracket, and that the dollars falling within the
            lower value AUM brackets will still be charged at the respective
            rates for those lower value AUM brackets regardless of the total
            value of Billable AUM.
            <br />
            <br />
            Please note that this service covers individual federal and state
            tax returns, not business entity returns such as Form 1120, 1120-S
            and 1065. If you sign your agreement and qualify before November
            15th of a year, you will be eligible for that year's tax filing. If
            you qualify after November 15th, we will not be able to guarantee
            the ability to prepare your current year tax return by the regular
            deadline, but may be able to do so on extension. For example,
            signing your agreement and transferring $2,300,000 in assets for
            Compound to manage on October 27, 2023 will make you eligible for
            in-house completion of your 2023 tax returns. Tax filing includes
            service credits of up to $3,000 per tax year at our standard tax
            filing rates. This covers most individual returns of medium
            complexity.
            <br />
            <br />
            All logos are trademarks of their respective owners. For more
            information, please see{' '}
            <a
              href="https://legal.compoundplanning.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'underline' }}
            >
              legal.compoundplanning.com
            </a>
            .
          </Text>
        </Col>
      );
    case DocumentModuleGroup.QuarterlyPerformanceUpdate:
      return (
        <Col css={{ gap: '$6', mt: '$4', w: '1034px' }}>
          <Text size="13" color="gray11" weight="medium">
            Disclaimer
          </Text>
          <Text size="13" color="gray10" css={{ lineHeight: '20px' }}>
            <Text>Returns and impact of Compound's Advisory Fees:</Text>
            <br />
            Fees are typically charged to clients’ accounts, unless otherwise
            indicated, based on the billable assets under management and the fee
            schedule agreed upon between Compound and the client.
            <br />
            <br />
            For clients whose fees are charged to accounts, displayed TWR is net
            of both third party fees and advisory fees paid to Compound.
            <br />
            <br />
            For clients whose accounts’ fees are charged to clients’ Credit
            Cards, the performance results are presented gross of Compound’s
            advisory fees. These returns do not reflect the deduction of any
            investment advisory fees charged by Compound.
            <br />
            <br />
            For more information on the advisory fees paid to Compound, please
            see our{' '}
            <Box
              as="a"
              href="https://compoundplanning.com/legal/crs"
              css={{ textDecoration: 'underline' }}
            >
              Form CRS
            </Box>{' '}
            and{' '}
            <Box
              as="a"
              href="https://compoundplanning.com/legal/adv"
              css={{ textDecoration: 'underline' }}
            >
              Form ADV 2A
            </Box>{' '}
            or consult with your Compound Financial Advisor.
          </Text>
        </Col>
      );
    case DocumentModuleGroup.AssetAllocationInvestmentProposal:
      return (
        <Col css={{ mt: '$4', w: '1034px' }}>
          <Disclosure
            summary={
              <Text size="13" color="gray11">
                Target/Hypothetical Performance Returns
              </Text>
            }
          >
            <Text size="13" color="gray10" css={{ lineHeight: '20px' }}>
              Target returns are hypothetical and are not a guarantee of future
              results. Actual returns may vary and are subject to market
              fluctuations and other risks. Past performance is not indicative
              of future results. Any projections or other forward-looking
              statements regarding future events or performance of investments,
              including without limitation, targets for investment returns,
              involve assumptions, risks and uncertainties which may cause
              actual results to differ materially from those expressed or
              implied in such projections or forward-looking statements.
              Accordingly, any projections or forward-looking statements should
              not be relied upon as accurate predictions of future results. All
              investments involve risk and may lose value. The calculations
              reflected herein are as of today. Capital market assumptions may
              change quickly and may materially alter the projections. Compound
              has no duty to inform you of any changes. Compound's investment
              team reviews, approves, and makes changes to capital market
              assumptions periodically. Compound uses third party data providers
              to inform forward looking capital market assumptions and has
              currently adopted the JP Morgan 2023 Long Term Capital Market
              Assumptions as the basis for forecasting.
            </Text>
          </Disclosure>
          <Disclosure
            summary={
              <Text size="13" color="gray11">
                Returns and impact of Compound's Advisory Fees
              </Text>
            }
          >
            <Text size="13" color="gray10" css={{ lineHeight: '20px' }}>
              <Text>Returns and impact of Compound's Advisory Fees:</Text>
              <br />
              Fees are typically charged to clients’ accounts, unless otherwise
              indicated, based on the billable assets under management and the
              fee schedule agreed upon between Compound and the client.
              <br />
              <br />
              For clients whose fees are charged to accounts, displayed TWR is
              net of both third party fees and advisory fees paid to Compound.
              <br />
              <br />
              For clients whose accounts’ fees are charged to clients’ Credit
              Cards, the performance results are presented gross of Compound’s
              advisory fees. These returns do not reflect the deduction of any
              investment advisory fees charged by Compound.
              <br />
              <br />
              For more information on the advisory fees paid to Compound, please
              see our{' '}
              <Box
                as="a"
                href="https://compoundplanning.com/legal/crs"
                css={{ textDecoration: 'underline' }}
              >
                Form CRS
              </Box>{' '}
              and{' '}
              <Box
                as="a"
                href="https://compoundplanning.com/legal/adv"
                css={{ textDecoration: 'underline' }}
              >
                Form ADV 2A
              </Box>{' '}
              or consult with your Compound Financial Advisor.
            </Text>
          </Disclosure>
        </Col>
      );
    case DocumentModuleGroup.SalesIntroduction:
      return (
        <Col css={{ gap: '$6', mt: '$4', w: '1034px' }}>
          <Text size="13" color="gray11" weight="medium">
            Disclaimer
          </Text>
          <Text size="13" color="gray10" css={{ lineHeight: '20px' }}>
            Subject to the terms and conditions of the{' '}
            <a
              href="https://legal.compoundplanning.com/client-agreements"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'underline' }}
            >
              Compound Client Agreements
            </a>
            . Compound Financial Inc. (“Compound Financial”) offers
            software-based financial management and planning tools. Investment
            advisory services are provided by Compound Advisors, Inc. (“Compound
            Advisers”), an SEC-registered investment adviser. Compound Tax, LLC
            (“Compound Tax”) provides tax consulting and compliance services.
            Compound Advisers and Compound Tax are wholly owned subsidiaries of
            Compound Financial. Altogether, we refer to our business as
            (“Compound”). Registration as an investment adviser does not imply
            any level of skill or training. Nothing on this document should be
            considered an offer, solicitation of an offer, or advice to buy or
            sell securities. Compound is not a licensed lender, law firm or
            insurance agency, and any such services mentioned in this document
            are provided by non-affiliated referrers. Account holdings are for
            illustrative purposes only and are not investment recommendations.
            The content on this document is for informational purposes only and
            does not constitute a comprehensive description of Compound’s
            investment advisory services. Refer to Compound’s Brochure (
            <a
              href="https://compoundplanning.com/legal/adv"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'underline' }}
            >
              Form ADV Part 2A
            </a>
            ) and Client Relationship Summary (
            <a
              href="https://compoundplanning.com/legal/crs"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'underline' }}
            >
              Form CRS
            </a>
            ) for more information. Brokerage services are provided by Charles
            Schwab & Co., Inc. (“Schwab,” Member SIPC). Access to alternative
            investment deals is available for accredited investors or qualified
            purchasers only.
            <br />
            <br />
            For target performance on Compound managed accounts and portfolios
            we will disclose the following:
            <br />
            <br />
            Target/Hypothetical Performance Returns: Target returns are
            hypothetical and are not a guarantee of future results. Actual
            returns may vary and are subject to market fluctuations and other
            risks. Past performance is not indicative of future results. Any
            projections or other forward-looking statements regarding future
            events or performance of investments, including without limitation,
            targets for investment returns, involve assumptions, risks and
            uncertainties which may cause actual results to differ materially
            from those expressed or implied in such projections or
            forward-looking statements. Accordingly, any projections or
            forward-looking statements should not be relied upon as accurate
            predictions of future results. All investments involve risk and may
            lose value. The calculations reflected herein are as of today.
            Capital market assumptions may change quickly and may materially
            alter the projections. Compound has no duty to inform you of any
            changes. Compound's investment team reviews, approves, and makes
            changes to capital market assumptions periodically. Compound uses
            third party data providers to inform forward looking capital market
            assumptions and has currently adopted the JP Morgan 2023 Long Term
            Capital Market Assumptions as the basis for forecasting.
            <br />
            <br />
            Returns and impact of Compound's Advisory Fees: Fees are typically
            charged to clients’ accounts, unless otherwise indicated, based on
            the billable assets under management and the fee schedule agreed
            upon between Compound and the client. For clients whose fees are
            charged to accounts, displayed TWR is net of both third party fees
            and advisory fees paid to Compound. For clients whose accounts’ fees
            are charged to clients’ Credit Cards, the performance results are
            presented gross of Compound’s advisory fees. These returns do not
            reflect the deduction of any investment advisory fees charged by
            Compound.
            <br />
            <br />
            All logos are trademarks of their respective owners. For more
            information, please see{' '}
            <a
              href="https://legal.compoundplanning.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'underline' }}
            >
              legal.compoundplanning.com
            </a>
            .
          </Text>
        </Col>
      );
    default:
      return null;
  }
}
export default Disclaimer;
