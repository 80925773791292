import capitalize from 'lodash/capitalize';
import compact from 'lodash/compact';
import get from 'lodash/get';
import partition from 'lodash/partition';
import { InstitutionName, PlaidAccountProvider } from 'utils/plaid/constants';
import { AssetTypes } from '@compoundfinance/compound-core/dist/types/account';
import { AssetTitles } from 'components/EquityManagement/Asset/constants';
import { AssetsDisplayOrder } from 'utils/constants/assets';
import { buildAssetAccountSection } from 'utils/presenters/accountSections';
import { AccountTypeLabels } from 'containers/Dashboard/Accounts/constants';
import UiUtils from 'utils/ui';
import {
  InstitutionStatus,
  PlaidAccount,
} from '@compoundfinance/compound-core/dist/types/plaid';
import {
  COMPOUND_PORTFOLIO_NAME,
  PLAID_ACCOUNT_TYPES,
} from 'shared/plaid/constants';
import PlaidAccountSharedUtils, {
  PlaidAccountType,
} from 'shared/plaid/assetBreakdown';
import CompoundInvestUtils from 'containers/Dashboard/CompoundInvest/CompoundInvestUtils';

const PLAID_ACCOUNT_SUB_TYPES = {
  ...CompoundInvestUtils.Constants.SubTypes,
  IRA: 'ira',
  '401k': '401k',
  '401a': '401a',
  '403b': '403b',
  '403B': '403B',
  '457b': '457b',
  SimpleIRA: 'simple ira',
  SepIRA: 'sep ira',
  SarSep: 'sarsep',
  Retirement: 'retirement',
  Roth: 'roth',
  Roth401k: 'roth 401k',
  Pension: 'pension',
  RRSP: 'rrsp',
} as const;

function isAsset(account: PlaidAccountType) {
  return (
    PlaidAccountSharedUtils.isInvestment(account) ||
    PlaidAccountSharedUtils.isBank(account)
  );
}

function isLiability(account: PlaidAccountType) {
  return (
    PlaidAccountSharedUtils.isCredit(account) ||
    PlaidAccountSharedUtils.isLoan(account)
  );
}

function getPlaidAssetType(account: PlaidAccount) {
  switch (account.type) {
    case PLAID_ACCOUNT_TYPES.CRYPTO:
      return AssetTypes.Crypto;
    case PLAID_ACCOUNT_TYPES.DEPOSITORY:
      return AssetTypes.Cash;
    case PLAID_ACCOUNT_TYPES.INVESTMENT:
      return AssetTypes.PublicInvestment;
    case PLAID_ACCOUNT_TYPES.CREDIT:
      return AssetTypes.CreditCard;
    case PLAID_ACCOUNT_TYPES.LOAN:
      return AssetTypes.Loan;
    default:
      return AssetTypes.Other;
  }
}

/**
 * From a list of plaid account objects, return a snapshot of the current total value of
 * all accounts, bucketed by asset type. The total value is derived from the
 * `currentBalance` property of the account object.
 *
 * @param accounts A list of plaid accounts
 * @param accounts.currentBalance The value of the account
 * @param accounts.type The class of account (e.g. depository or loan)
 * @param accounts.id The ID of the corresponding account
 * @param filterOutMap A map optionally supplied that denotes which accounts to filter out. If the map is supplied, then it should be used when calculating appropriate balances
 *
 */
function getPlaidAccountBreakdown(
  accounts: PlaidAccount[],
  filterOutMap?: Record<string, boolean>,
) {
  const accountBreakdown = {
    liabilities: 0,
    investments: 0,
    bankAccounts: 0,
    net: 0,
  };

  accounts.forEach(
    (acct: { currentBalance: number; type: string; id?: string }) => {
      const { currentBalance, type, id } = acct;
      if (filterOutMap && filterOutMap[id as string]) {
        return;
      }
      if (isLiability({ type })) {
        accountBreakdown.liabilities += currentBalance;
        accountBreakdown.net -= currentBalance;
      } else if (
        PlaidAccountSharedUtils.isInvestment({ type }) ||
        PlaidAccountSharedUtils.isCrypto({ type })
      ) {
        accountBreakdown.investments += currentBalance;
        accountBreakdown.net += currentBalance;
      } else {
        accountBreakdown.bankAccounts += currentBalance;
        accountBreakdown.net += currentBalance;
      }
    },
  );

  return accountBreakdown;
}

// Determine which accounts, if any, should be removed from net worth calculations
function getFilterList(accounts, filterMap) {
  if (!filterMap) {
    return [];
  }

  return accounts.filter((a) => filterMap[a.id]).map((a) => a.id);
}

function getPlaidAccountSections(
  plaidAccounts: PlaidAccount[],
  filterOutMap?: Record<string, boolean>,
  splitPublicInvestmentsAndCompoundInvestments: boolean = false,
) {
  const groupedAccounts =
    PlaidAccountSharedUtils.groupByAccountType(plaidAccounts);

  // TODO: we *should* be able to loop over this rather than enumerate each key by hand?
  const [publicInvestments, compoundInvestments] = partition(
    groupedAccounts[AssetTypes.PublicInvestment],
    (account) =>
      !splitPublicInvestmentsAndCompoundInvestments ||
      (account.provider !== PlaidAccountProvider.BlackDiamond &&
        account.provider !== PlaidAccountProvider.Orion),
  );
  const deposits = groupedAccounts[AssetTypes.Cash];
  const creditCards = groupedAccounts[AssetTypes.CreditCard];
  const loans = groupedAccounts[AssetTypes.Loan];
  const cryptoAccounts = groupedAccounts[AssetTypes.Crypto];
  const uncategorizedAccounts = groupedAccounts[AssetTypes.Other];

  let assetAccountSections = compact([
    deposits.length > 0 &&
      buildAssetAccountSection({
        title: AssetTitles[AssetTypes.Cash],
        accounts: deposits,
        type: AssetTypes.Cash,
        displayOrder: AssetsDisplayOrder[AssetTypes.Cash],
        filtered: getFilterList(deposits, filterOutMap),
      }),
    publicInvestments.length > 0 &&
      buildAssetAccountSection({
        title: AssetTitles[AssetTypes.PublicInvestment],
        accounts: publicInvestments,
        type: AssetTypes.PublicInvestment,
        displayOrder: AssetsDisplayOrder[AssetTypes.PublicInvestment],
        filtered: getFilterList(publicInvestments, filterOutMap),
      }),
    compoundInvestments.length > 0 &&
      buildAssetAccountSection({
        title: AssetTitles[AssetTypes.CompoundInvestAccount],
        accounts: compoundInvestments,
        type: AssetTypes.CompoundInvestAccount,
        displayOrder: AssetsDisplayOrder[AssetTypes.CompoundInvestAccount],
        filtered: getFilterList(compoundInvestments, filterOutMap),
      }),
    cryptoAccounts.length > 0 &&
      buildAssetAccountSection({
        title: AssetTitles[AssetTypes.Crypto],
        accounts: cryptoAccounts,
        type: AssetTypes.Crypto,
        filtered: getFilterList(cryptoAccounts, filterOutMap),
        displayOrder: AssetsDisplayOrder[AssetTypes.Crypto],
      }),
    uncategorizedAccounts.length > 0 &&
      buildAssetAccountSection({
        title: 'Uncategorized',
        accounts: uncategorizedAccounts,
        type: AssetTypes.Other,
        filtered: getFilterList(uncategorizedAccounts, filterOutMap),
        displayOrder: Infinity,
      }),
  ]);

  let liabilityAccountSections = compact([
    creditCards.length > 0 &&
      buildAssetAccountSection({
        title: AssetTitles[AssetTypes.CreditCard],
        accounts: creditCards,
        type: AssetTypes.CreditCard,
        displayOrder: AssetsDisplayOrder[AssetTypes.CreditCard],
        filtered: getFilterList(creditCards, filterOutMap),
      }),
    loans.length > 0 &&
      buildAssetAccountSection({
        title: AssetTitles[AssetTypes.Loan],
        accounts: loans,
        type: AssetTypes.Loan,
        displayOrder: AssetsDisplayOrder[AssetTypes.Loan],
        filtered: getFilterList(loans, filterOutMap),
      }),
  ]);

  return {
    assetAccountSections,
    liabilityAccountSections,
  };
}

function isInstitutionError(account: PlaidAccount) {
  return (
    account.institution &&
    account.institution.status === InstitutionStatus.Error
  );
}

function getOfficialName(
  account: Pick<PlaidAccount, 'officialName' | 'label'>,
) {
  return account.label === InstitutionName.Compound
    ? COMPOUND_PORTFOLIO_NAME
    : account.officialName;
}

/**
 * Util used for determining the "asset type" of the underlying plaid account
 * @param account
 */
function getAssetType(account: PlaidAccount) {
  if (isSelfServeProvider(account) && PlaidAccountSharedUtils.isBank(account)) {
    return AssetTypes.ManualBankAccount;
  }
  if (
    isSelfServeProvider(account) &&
    PlaidAccountSharedUtils.isInvestment(account)
  ) {
    return AssetTypes.ManualInvestmentAccount;
  }

  if (
    isSelfServeProvider(account) &&
    PlaidAccountSharedUtils.isCrypto(account)
  ) {
    return AssetTypes.ManualCryptoAccount;
  }

  return AssetTypes.PlaidAccount;
}

/**
 * Returns true if the account was manually added
 * @param account
 */
function isSelfServeProvider(account: PlaidAccount) {
  return account.provider === PlaidAccountProvider.SelfServe;
}

function getSyncedProvider(account: PlaidAccount) {
  return account.provider === PlaidAccountProvider.BlackDiamond
    ? PlaidAccountProvider.Schawb
    : account.provider;
}

function getAccountName(account: PlaidAccount) {
  const name = UiUtils.capitalizeFirstLetters(
    account.label ||
      account.officialName ||
      account.name ||
      account.institution.name,
  );

  if (
    account.provider === PlaidAccountProvider.BlackDiamond &&
    !account.label
  ) {
    return name.split('-')[0];
  }

  return name;
}

function accountTypeLabel(account: PlaidAccount) {
  return (
    get(AccountTypeLabels, [account.type, account.subtype]) ??
    capitalize(account.subtype) ??
    capitalize(account.type)
  );
}

function isRetirement(account: Pick<PlaidAccount, 'subtype'>) {
  return [
    PLAID_ACCOUNT_SUB_TYPES['401k'],
    PLAID_ACCOUNT_SUB_TYPES['401a'],
    PLAID_ACCOUNT_SUB_TYPES['403b'],
    PLAID_ACCOUNT_SUB_TYPES['403B'],
    PLAID_ACCOUNT_SUB_TYPES['457b'],
    PLAID_ACCOUNT_SUB_TYPES.IRA,
    PLAID_ACCOUNT_SUB_TYPES.IRAAccounts,
    PLAID_ACCOUNT_SUB_TYPES.ContributoryIRA,
    PLAID_ACCOUNT_SUB_TYPES.SimpleIRA,
    PLAID_ACCOUNT_SUB_TYPES.SepIRA,
    PLAID_ACCOUNT_SUB_TYPES.Retirement,
    PLAID_ACCOUNT_SUB_TYPES.RetirementAccounts,
    PLAID_ACCOUNT_SUB_TYPES.Roth,
    PLAID_ACCOUNT_SUB_TYPES.Roth401k,
    PLAID_ACCOUNT_SUB_TYPES.Pension,
    PLAID_ACCOUNT_SUB_TYPES.RRSP,
    PLAID_ACCOUNT_SUB_TYPES.SarSep,
  ].includes(account.subtype as any);
}

const PlaidAccountUtils = {
  isAsset,
  isLiability,
  isInstitutionError,
  isRetirement,
  getPlaidAccountBreakdown,
  getPlaidAccountSections,
  getOfficialName,
  getAccountName,
  getAssetType,
  isSelfServeProvider,
  getSyncedProvider,
  getPlaidAssetType,
  accountTypeLabel,
};

export default PlaidAccountUtils;
