import { ErrorBoundary } from '@sentry/react';
import { LAYOUT_CONTENT_WIDTH, Layout } from 'components/Layout';
import ModuleContainer from 'containers/Dashboard/Document/components/ModuleContainer';
import {
  Badge,
  Box,
  Button,
  Col,
  Dialog,
  Key,
  Row,
  Text,
  Tooltip,
  useDialog,
} from '@compoundfinance/design-system';
import { AnimatePresence } from 'framer-motion';
import { atom } from 'jotai';
import noop from 'lodash/noop';
import { styled } from '@compoundfinance/design-system/dist/stitches.config';
import buildRequest from 'utils/api';
import { REQUEST_TYPES } from 'utils/constants/axios';
import { isProduction } from 'utils/env';
import AddModuleTrigger from './AddModuleTrigger';
import Disclaimer from './Disclaimer';
import ModuleContextProvider from './ModuleContextProvider';
import ModuleSelector from './ModuleSelector';
import TableOfContents from './TableOfContents';
import useIsDocumentEditor from '../../hooks/useIsDocumentEditor';
import { AMPLITUDE_EVENTS, amplitudeLogEvent } from 'utils/amplitude';
import ShareModal from './ShareModal';
import { SplitFeature } from 'utils/featureFlag';
import useFeatureFlag from 'hooks/useFeatureFlag';
import useDocument from '../../hooks/useDocument';

const DIALOG_INDEX = 99999;

export const tableOfContentsModuleVisibilityAtom = atom<
  Record<string, Boolean>
>({});

export const tableOfContentsWriteModuleVisibilityAtom = atom(
  null,
  (get, set, action: { id: string; isVisible: boolean }) => {
    set(tableOfContentsModuleVisibilityAtom, {
      ...get(tableOfContentsModuleVisibilityAtom),
      [action.id]: action.isVisible,
    });
  },
);

const Grid = styled(Box, {
  display: 'grid',
  margin: '0 auto',
  gridTemplateColumns: '200px 1fr',
  [`@media(max-width: ${LAYOUT_CONTENT_WIDTH})`]: {
    gridTemplateColumns: 'min-content',
    '[data-table-of-contents]': {
      display: 'none',
    },
  },
});

interface DocumentViewerProps {
  onClose: () => void;
}

function DocumentViewer(props: DocumentViewerProps) {
  const { document, mutateDocument } = useDocument();
  const { onClose } = props;
  const { isOpen, setIsOpen, open } = useDialog();
  const isEditor = useIsDocumentEditor();
  const enableMagicLinks = useFeatureFlag(
    SplitFeature.ENABLE_DOCUMENT_MAGIC_LINKS,
    true,
  );

  const onPublish = async () => {
    amplitudeLogEvent(AMPLITUDE_EVENTS.ProposalPublish, {
      id: document.id,
      userId: document.userId,
      name: document.title,
      type: document.type,
    });
    // would be nice to have SWR 2.0 and leverage useSWRMutation
    const res = await buildRequest(
      `/api/documents/${document.id}/publish`,
      true,
      {},
      REQUEST_TYPES.POST,
      {
        userId: document.userId,
      },
    );

    if (res?.data.document) {
      mutateDocument();
    }
  };

  const onUnpublish = async () => {
    amplitudeLogEvent(AMPLITUDE_EVENTS.ProposalUnpublish, {
      id: document.id,
      userId: document.userId,
      name: document.title,
      type: document.type,
    });
    const res = await buildRequest(
      `/api/documents/${document.id}/unpublish`,
      true,
      {},
      REQUEST_TYPES.POST,
      {
        userId: document.userId,
      },
    );

    if (res?.data.document) {
      mutateDocument();
    }
  };

  const onClickTitle = async () => {
    const title = window.prompt('Enter new title', document.title);

    const res = await buildRequest(
      `/api/documents/${document.id}/title`,
      true,
      { title: title ? title : null },
      REQUEST_TYPES.PUT,
      {
        userId: document.userId,
      },
    );

    if (res?.data.document) {
      mutateDocument();
    }
  };

  if (!document) return null;

  // TODO validate there are no dangling documentSections

  return (
    <>
      <ShareModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        documentId={document.id}
      />
      <Dialog
        isOpen={true}
        setIsOpen={noop}
        onClose={onClose}
        css={{
          h: '100vh',
          maxHeight: '100vh',
          w: '100%',
          p: 0,
          bg: '$gray2',
          borderRadius: 0,
          overflow: 'hidden',
        }}
        zIndex={DIALOG_INDEX}
        hideCross
      >
        <Box
          css={{
            h: '100%',
            overflowY: 'auto',
            scrollBehavior: 'smooth',
          }}
        >
          <Col>
            <Col
              as={'nav'}
              css={{
                bg: isEditor ? '$indigo2' : '$gray0',
                w: '100%',
                h: '$72',
                borderBottom: isEditor
                  ? '1px solid $indigo4'
                  : '1px solid $gray4',
              }}
            >
              <Row
                css={{
                  jc: 'space-between',
                  ai: 'center',
                  h: '100%',
                  px: '$24',
                }}
              >
                <Button
                  variant="naked"
                  onClick={onClose}
                  css={{
                    p: '$8',
                    border: '1px solid transparent',

                    '&:active, &:focus': {
                      br: '$4',
                      border: '1px solid $gray6',
                      bg: '$gray1',
                    },
                  }}
                >
                  <Row css={{ gap: '$4', ai: 'center', cursor: 'pointer' }}>
                    <Key css={{ width: 'max-content', px: '$4' }}>Esc</Key>
                    <Text size="13" color="gray11">
                      {' '}
                      to exit
                    </Text>
                  </Row>
                </Button>
                {/* TODO Center relative to viewport */}
                <Row css={{ gap: '$8', ai: 'center', cursor: 'pointer' }}>
                  {!document.publishedAt && <Badge>Draft</Badge>}
                  {isEditor ? (
                    <Tooltip>
                      <Tooltip.Trigger asChild>
                        <Button variant="naked" onClick={onClickTitle}>
                          <Text color="indigo10">{document.title}</Text>
                        </Button>
                      </Tooltip.Trigger>
                      <Tooltip.Content>Edit document title</Tooltip.Content>
                    </Tooltip>
                  ) : (
                    <Text color={'gray12'}>{document.title}</Text>
                  )}
                </Row>
                <Row css={{ gap: '$8' }}>
                  {/* <Button variant="ghost">Email advisor</Button>
                <Button variant="ghost">Book meeting with advisor</Button> */}
                  {!document.publishedAt && (
                    <Button
                      size="small"
                      css={{ bg: '$indigo11', border: '1px solid $indigo8' }}
                      onClick={onPublish}
                    >
                      Publish
                    </Button>
                  )}

                  {isEditor && document.publishedAt && (
                    <>
                      {enableMagicLinks ? (
                        <Button size="small" variant="ghost" onClick={open}>
                          Share
                        </Button>
                      ) : null}
                      <Button
                        size="small"
                        variant="ghost"
                        onClick={onUnpublish}
                      >
                        Unpublish
                      </Button>
                    </>
                  )}
                </Row>
              </Row>
            </Col>

            <Layout>
              <Grid>
                {/* Table of contents */}
                <TableOfContents document={document} />

                {/* Modules */}
                <AnimatePresence>
                  <Col>
                    {isEditor && document.moduleOrder.length === 0 && (
                      <AddModuleTrigger
                        mutateDocument={mutateDocument}
                        alwaysShow
                      />
                    )}

                    {document.moduleOrder.map((moduleId) => {
                      const module = document.modules?.[moduleId];
                      if (!module) return null;

                      return (
                        <ErrorBoundary
                          key={module.id}
                          fallback={(error) =>
                            isProduction ? (
                              <></>
                            ) : (
                              <>
                                <ModuleContainer css={{ p: '$48' }}>
                                  <Text weight="medium">
                                    Unable to render component properly:
                                  </Text>
                                  <pre>{error.error.message}</pre>
                                </ModuleContainer>
                                {isEditor ? (
                                  <AddModuleTrigger
                                    mutateDocument={mutateDocument}
                                    moduleIdToInsertAfter={module.id}
                                  />
                                ) : (
                                  <Box css={{ h: '$12' }}></Box>
                                )}
                              </>
                            )
                          }
                        >
                          <ModuleContextProvider
                            module={module}
                            documentProperties={document.properties}
                            mutateDocument={mutateDocument}
                          >
                            {/* Disable motion because there are animation bugs when inserting modules https://github.com/framer/motion/issues/1524 */}
                            {/* TODO Re-enable animations when document viewer is not presented via a modal */}
                            {/* <motion.div layout> */}
                            <ModuleSelector
                              mutateDocument={mutateDocument}
                              isEditor={isEditor}
                            />
                            {isEditor ? (
                              <AddModuleTrigger
                                mutateDocument={mutateDocument}
                                moduleIdToInsertAfter={module.id}
                              />
                            ) : (
                              <Box css={{ h: '$12' }}></Box>
                            )}
                            {/* </motion.div> */}
                          </ModuleContextProvider>
                        </ErrorBoundary>
                      );
                    })}
                    <Disclaimer documentType={document.type} />
                  </Col>
                </AnimatePresence>
              </Grid>
            </Layout>
          </Col>
        </Box>
      </Dialog>
    </>
  );
}

export default DocumentViewer;
