import React, { useCallback, useEffect, useMemo, useState } from 'react';
import isNil from 'lodash/isNil';
import UiUtils from 'utils/ui';
import { Box, Row, Text } from '@compoundfinance/design-system';
import { Line, Bar } from '@visx/shape';
import { TooltipWithBounds, useTooltip } from '@visx/tooltip';
import { WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip';
import { localPoint } from '@visx/event';
import { LinearGradient } from '@visx/gradient';
import { scaleTime, scaleLinear } from '@visx/scale';
import { bisector, extent, min, max } from 'd3';
import { timeFormat } from 'd3-time-format';
import { AxisLeft } from '@visx/axis';
import { Group } from '@visx/group';
import { Threshold } from '@visx/threshold';
import { line } from 'd3-shape';
import Charting, { MARGIN } from 'components/Charting';
import { styled } from '@compoundfinance/design-system/dist/stitches.config';
import getMinAndMaxForChart from 'utils/getMinAndMaxForChart';
import { COLORS } from 'shared/chart/constants';

function TooltipRow({
  tooltipData,
  tooltipValueFormat,
  name,
  color,
}: {
  tooltipData: ChartData | undefined;
  tooltipValueFormat: (value: number) => string;
  name: string;
  color: string;
}) {
  return (
    <Row css={{ ai: 'center', gap: '$4', w: '100%' }}>
      <Box css={{ w: 6, h: 6, bg: color, br: '$round' }} />
      <Row css={{ flex: 1, gap: '$10', jc: 'space-between' }}>
        <Text color="gray13" size="12" weight="medium">
          {name}
        </Text>
        <Text color="gray10" size="12" weight="medium">
          {tooltipData && tooltipValueFormat(getValue(tooltipData))}
        </Text>
      </Row>
    </Row>
  );
}

function ExtraLinesTooltip({
  data,
  name,
  color,
  metadata,
  tooltipValueFormat,
}: {
  data: ChartData[];
  name: string;
  color: string;
  metadata: { index: number; x0: Date } | undefined;
  tooltipValueFormat: (value: number) => string;
}) {
  const { showTooltip, tooltipData }: WithTooltipProvidedProps<ChartData> =
    useTooltip();

  useEffect(() => {
    if (!isNil(metadata)) {
      const d0 = data[metadata.index - 1];
      const d1 = data[metadata.index];
      let d = d0;
      if (d1 && getDate(d1)) {
        d =
          metadata.x0.valueOf() - getDate(d0).valueOf() >
          getDate(d1).valueOf() - metadata.x0.valueOf()
            ? d1
            : d0;
      }
      showTooltip({ tooltipData: d });
    }
  }, [metadata, data, showTooltip]);

  return (
    <TooltipRow
      name={name}
      color={color}
      tooltipData={tooltipData}
      tooltipValueFormat={tooltipValueFormat}
    ></TooltipRow>
  );
}

const { Path, Container } = Charting;

const getDate = (d: ChartData) => {
  const date = new Date(d.date);
  const MINUTE_IN_MILLISECONDS = 60_000;
  const now_utc = new Date(
    date.getTime() + date.getTimezoneOffset() * MINUTE_IN_MILLISECONDS,
  );
  return now_utc;
};
const getValue = (d: ChartData) => d.net;
const bisectDate = bisector((d: ChartData) => new Date(d.date)).left;
const formatDate = timeFormat('%b %d, %Y');

const StyledTooltipWithBounds = styled(TooltipWithBounds, {
  display: 'flex',
  flexDirection: 'column',
  gap: '$4',
  ai: 'flex-start',
  padding: '8px 12px',
  minWidth: 132,
  textAlign: 'center',
  border: '$borderStyles$default',
  borderRadius: '$6 !important',
  boxShadow: '$small',
});

export interface ChartData {
  net: number;
  date: Date | string;
}
interface LineChartProps {
  width: number;
  height: number;
  data: ChartData[];
  interpolationDates?: Date[];
  extraLines?: {
    color: string;
    name: string;
    data: ChartData[];
  }[];
  formatYAxis?: (value: number) => string;
  formatTooltip?: (value: number) => string;
  formatTooltipDate?: (date: Date) => string;
  margin?: typeof MARGIN;
}

const getTicks = (data: ChartData[]) => {
  return [
    data.at(0)!,
    ...(data.length > 30 ? [data.at(data.length / 2)!] : []),
    ...(data.length > 90 ? [data.at(data.length / 4)!] : []),
    data.at(-1)!,
  ];
};

const timezoneCorrectionInMs = new Date().getTimezoneOffset() * 60 * 1_000;

const LineChart = (props: LineChartProps) => {
  const {
    width,
    height,
    data: interpolatedData,
    extraLines,
    formatYAxis,
    formatTooltip,
    formatTooltipDate,
    interpolationDates = [],
    margin = MARGIN,
  } = props;
  const {
    showTooltip,
    hideTooltip,
    tooltipLeft,
    tooltipTop,
    tooltipData,
  }: WithTooltipProvidedProps<ChartData> = useTooltip();
  const tooltip = tooltipData && tooltipLeft && tooltipTop;
  const xMax = width - margin.left - margin.right - 5;
  const yMax = height - margin.top - margin.bottom;

  const interpolationDateValues = useMemo(
    () => interpolationDates.map((date) => date.valueOf()),
    [interpolationDates],
  );

  const originalData = useMemo(
    () =>
      interpolatedData.map((datum) => {
        const correctedDate = getDate(datum).valueOf() - timezoneCorrectionInMs;
        if (interpolationDateValues.includes(correctedDate)) {
          return { ...datum, net: NaN };
        }
        return datum;
      }),
    [interpolatedData, interpolationDateValues],
  );

  const interpolationData = useMemo(() => {
    let interpolationData: ChartData[] = [];
    for (let i = 0; i < interpolatedData.length; i++) {
      const datum = interpolatedData[i];
      const correctedDate = getDate(datum).valueOf() - timezoneCorrectionInMs;
      const previousDatum = interpolatedData[i - 1];
      const nextDatum = interpolatedData[i + 1];
      if (interpolationDateValues.includes(correctedDate)) {
        interpolationData[i - 1] = previousDatum;
        interpolationData.push(datum);
        interpolationData.push(nextDatum);
        const correctedNextDate =
          getDate(nextDatum).valueOf() - timezoneCorrectionInMs;
        if (!interpolationDateValues.includes(correctedNextDate)) {
          i++;
        }
      } else {
        interpolationData.push({ ...datum, net: NaN });
      }
    }
    return interpolationData;
  }, [interpolatedData, interpolationDateValues]);

  const [tooltipMetadata, setTooltipMetadata] = useState<
    { index: number; x0: Date } | undefined
  >();

  const minData = Math.min(
    min(extraLines?.flatMap(({ data }) => data) ?? [], getValue) ??
      Number.MAX_VALUE,
    min(interpolatedData, getValue) ?? Number.MAX_VALUE,
  );
  const maxData = Math.max(
    max(extraLines?.flatMap(({ data }) => data) ?? [], getValue) ??
      Number.MIN_VALUE,
    max(interpolatedData, getValue) ?? Number.MIN_VALUE,
  );
  const { minValue, maxValue } = getMinAndMaxForChart(minData, maxData);
  const hasNegativeAndPositive = minValue < 0 && maxValue > 0;

  const xScale = useMemo(
    () =>
      scaleTime({
        range: [margin.left, xMax],
        domain: extent(interpolatedData, getDate) as [Date, Date],
      }),
    [margin.left, xMax, interpolatedData],
  );

  const yScale = React.useMemo(
    () =>
      scaleLinear({
        range: [yMax, margin.bottom],
        domain: [minValue, maxValue],
      }),
    [yMax, margin.bottom, minValue, maxValue],
  );

  // tooltip handler
  const handleTooltip = useCallback(
    (
      event:
        | React.TouchEvent<SVGRectElement>
        | React.MouseEvent<SVGRectElement>,
    ) => {
      const { x } = localPoint(event) || { x: 0 };
      const x0 = xScale.invert(x - margin.left);
      const index = bisectDate(interpolatedData, x0, 1);
      const d0 = interpolatedData[index - 1];
      const d1 = interpolatedData[index];
      let d = d0;
      if (d1 && getDate(d1)) {
        d =
          x0.valueOf() - getDate(d0).valueOf() >
          getDate(d1).valueOf() - x0.valueOf()
            ? d1
            : d0;
      }
      setTooltipMetadata({ index, x0 });
      showTooltip({
        tooltipData: d,
        tooltipLeft: xScale(getDate(d)),
        tooltipTop: yScale(getValue(d)),
      });
    },
    [xScale, margin.left, interpolatedData, showTooltip, yScale],
  );

  const coloredPath = useMemo(
    () =>
      originalData.flatMap((datum, index) => {
        const previousPoint = originalData[index - 1];
        if (!previousPoint) return [];
        const previousValue = getValue(previousPoint);
        const currentValue = getValue(datum);
        function pathColor(value) {
          return value >= 0 ? COLORS.greenStroke : COLORS.redStroke;
        }

        const isCrossingZero = previousValue * currentValue < 0;
        if (isCrossingZero) {
          const ratio = Math.abs(
            previousValue / (currentValue - previousValue),
          );
          const middleDate = new Date(
            getDate(previousPoint).valueOf() +
              (getDate(datum).valueOf() - getDate(previousPoint).valueOf()) *
                ratio,
          );

          // Create two line segments with different colors
          const firstSegment = line<ChartData>()
            .x((d) => xScale(getDate(d)))
            .y((d) => yScale(getValue(d)))([
            previousPoint,
            { ...datum, date: middleDate, net: 0 },
          ]);

          const secondSegment = line<ChartData>()
            .x((d) => xScale(getDate(d)))
            .y((d) => yScale(getValue(d)))([
            { ...previousPoint, date: middleDate, net: 0 },
            datum,
          ]);

          return [
            <Path
              key={`${index}-1`}
              d={firstSegment}
              color={pathColor(previousValue)}
            />,
            <Path
              key={`${index}-2`}
              d={secondSegment}
              color={pathColor(currentValue)}
            />,
          ];
        } else {
          // @ts-ignore
          const pathSegment: any = line()
            .x((d) => xScale(getDate(datum)))
            .y((d) => yScale(getValue(datum)))([previousPoint, datum]);

          return (
            <Path key={index} d={pathSegment} color={pathColor(currentValue)} />
          );
        }
      }),
    [originalData, xScale, yScale],
  );

  const tooltipValueFormat =
    formatTooltip ??
    ((value: number) => UiUtils.nFormatter(value, 2, false, true));

  const tooltipDateFormat = formatTooltipDate ?? formatDate;
  return (
    <Box css={{ position: 'relative' }}>
      <Container
        width={width}
        height={height}
        xMax={xMax}
        yMax={yMax}
        yScale={yScale}
        xScale={xScale}
      >
        <LinearGradient
          id="circle-gradient"
          from="var(--colors-green8)"
          to={COLORS.greenStroke}
          toOpacity={1}
        />
        <LinearGradient
          id="circle-negative-gradient"
          from="var(--colors-red9)"
          to="var(--colors-red11)"
          toOpacity={1}
        />
        <LinearGradient
          id="area-gradient"
          from={COLORS.greenGradientStart}
          to={COLORS.greenGradientStop}
          toOpacity={1}
        />
        <LinearGradient
          id="area-negative-gradient"
          from={COLORS.redGradientStart}
          to={COLORS.redGradientStop}
          toOpacity={1}
        />
        <Threshold
          id="threshold"
          data={interpolatedData}
          x={(d) => xScale(getDate(d))}
          y0={(d) => yScale(getValue(d))}
          y1={() => Math.min(yScale(0), yScale(minValue))}
          clipAboveTo={minValue < 0 ? minValue : 0}
          clipBelowTo={height}
          {...(minValue < 0 && {
            belowAreaProps: {
              fill: 'url(#area-negative-gradient)',
            },
          })}
          {...(maxValue > 0 && {
            aboveAreaProps: {
              fill: 'url(#area-gradient)',
            },
          })}
        />
        {extraLines?.map(({ color, data }) => (
          <Path
            data={data}
            x={(d) => xScale(getDate(d))}
            y={(d) => yScale(getValue(d))}
            color={color}
            dashed
          />
        ))}
        <Group>
          {hasNegativeAndPositive ? coloredPath : null}
          {minValue >= 0 && (
            <Path
              data={originalData}
              x={(d) => xScale(getDate(d))}
              y={(d) => yScale(getValue(d))}
              color={COLORS.greenStroke}
              stroke-linecap="round"
              defined={(d) => !isNaN(d.net)}
            />
          )}
          {maxValue <= 0 && (
            <Path
              data={originalData}
              x={(d) => xScale(getDate(d))}
              y={(d) => yScale(getValue(d))}
              defined={(d) => !isNaN(d.net)}
              color={COLORS.redStroke}
            />
          )}
          <Path
            // Draw a dashed grey line over interpolated gaps
            data={interpolationData}
            x={(d) => xScale(getDate(d))}
            y={(d) => yScale(getValue(d))}
            defined={(d) => !isNaN(d.net)}
            color="gray"
            dashed
          />
        </Group>
        <AxisLeft
          hideAxisLine
          hideTicks
          tickLabelProps={() => ({
            fill: 'var(--colors-gray10)',
            fontSize: 11,
            textAnchor: 'end',
            dx: '-4px',
          })}
          left={margin.left}
          scale={yScale}
          numTicks={4}
          tickFormat={(value) =>
            formatYAxis?.(value as number) ??
            UiUtils.nFormatter(value as number, 2, false, true)
          }
        />
        <Group>
          {!hasNegativeAndPositive && (
            <line
              x1={margin.left}
              x2={xMax}
              y1={yMax}
              y2={yMax}
              stroke={'var(--colors-gray6)'}
              strokeWidth={1}
            />
          )}
          {interpolatedData.length >= 2 &&
            getTicks(interpolatedData).map((datum, index) => {
              const xPosition = xScale(getDate(datum));
              return (
                <text
                  key={index}
                  y={yMax + margin.top + 8}
                  x={xPosition}
                  fontSize={11}
                  fill="var(--colors-gray9)"
                  textAnchor={
                    xPosition <= margin.left
                      ? 'start'
                      : xPosition >= xMax
                      ? 'end'
                      : 'middle'
                  }
                >
                  {formatDate(getDate(datum))}
                </text>
              );
            })}
        </Group>
        <Bar
          x={margin.left}
          y={margin.top}
          width={xMax}
          height={yMax}
          fill="transparent"
          rx={14}
          onTouchStart={handleTooltip}
          onTouchMove={handleTooltip}
          onMouseMove={handleTooltip}
          onMouseLeave={() => hideTooltip()}
        />

        {tooltip && (
          <g>
            <Line
              from={{ x: tooltipLeft, y: margin.top }}
              to={{ x: tooltipLeft, y: yMax + margin.top }}
              stroke="var(--colors-gray8)"
              strokeWidth={1}
              pointerEvents="none"
              strokeDasharray="5,2"
            />

            <g>
              <circle
                cx={tooltipLeft}
                cy={tooltipTop + 1}
                r={4}
                fill="black"
                fillOpacity={0.1}
                stroke="black"
                strokeOpacity={0.1}
                strokeWidth={2}
                pointerEvents="none"
              />
              <circle
                cx={tooltipLeft}
                cy={tooltipTop}
                r={4}
                fill={
                  tooltipData.net >= 0
                    ? 'url(#circle-gradient)'
                    : 'url(#circle-negative-gradient)'
                }
                stroke="white"
                strokeWidth={2}
                pointerEvents="none"
              />
            </g>
          </g>
        )}
      </Container>
      {tooltip && (
        <StyledTooltipWithBounds
          key={Math.random()}
          top={tooltipTop - 12}
          left={tooltipLeft + 32}
        >
          <Text color="gray10" size="10">
            {tooltipDateFormat(getDate(tooltipData))}
          </Text>
          {extraLines && extraLines.length > 0 ? (
            <TooltipRow
              name="This Account"
              color={COLORS.greenStroke}
              tooltipData={tooltipData}
              tooltipValueFormat={tooltipValueFormat}
            ></TooltipRow>
          ) : (
            <Text color="gray13" size="12" weight="medium">
              {tooltipValueFormat(getValue(tooltipData))}
            </Text>
          )}
          {extraLines?.map(({ data, name, color }) => (
            <ExtraLinesTooltip
              data={data}
              name={name}
              color={color}
              metadata={tooltipMetadata}
              tooltipValueFormat={tooltipValueFormat}
            />
          ))}
        </StyledTooltipWithBounds>
      )}
    </Box>
  );
};

export default LineChart;
