import { useMemo } from 'react';
import { createSelector } from 'reselect';
import has from 'lodash/has';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import sortBy from 'lodash/sortBy';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import reduce from 'lodash/reduce';

import {
  CompoundAsset,
  OtherAsset,
  RealEstate,
  Loan,
  AssetFormValues,
} from 'components/EquityManagement/Asset/types';
import { ApplicationRootState } from 'types';
import useTypedSelector from 'hooks/typedSelector';
import { PrivateInvestment, PrivateInvestmentType } from 'types/equity';
import { TaxProfile } from 'types/user';
import {
  AssetAccountSectionTypes,
  AssetTypes,
} from '@compoundfinance/compound-core/dist/types/account';
import { PlaidAccount } from '@compoundfinance/compound-core/dist/types/plaid';
import PlaidAccountUtils from 'utils/plaid/accounts';
import { FileEntityTypes } from 'utils/file/types';
import getInstitutionImage from 'utils/assets/institutionLogos';
import { InstitutionName, PlaidAccountProvider } from 'utils/plaid/constants';
import AccountPageUtils from 'containers/Dashboard/Accounts/utils';
import getCompanyImage from './companyLogos';
import { AccountType } from 'containers/Dashboard/Accounts/types';
import { COMPOUND_COLORS } from 'style/theme';
import { FundInvestment, LPExposure } from 'types/fundInvestments';
import FundInvestmentUtils from 'utils/fundInvestment';
import { InvestmentValuationType } from 'utils/constants/privateInvestments';
import { isCompoundIntelligentPortfolio } from 'utils/investments';
import PlaidAccountSharedUtils from 'shared/plaid/assetBreakdown';
import PrivateInvestmentUtils from 'utils/privateInvestment/utils';
import OwnershipAdjustmentUtils from './ownershipAdjustment';
import { PrivateEquityAccount } from '@compoundfinance/compound-core/dist/types/equity';
import { IntegrationType } from 'utils/constants/integration';

type ImageableAsset = PlaidAccount | FundInvestment | CompoundAsset;

export const URL_REGEX = /http[s]?:\/\//;

function isLiability(assetType: AssetTypes) {
  return (
    assetType === AssetTypes.OtherLiability ||
    assetType === AssetTypes.CreditCard ||
    assetType === AssetTypes.Loan
  );
}

function isAsset(assetType: AssetTypes) {
  return !isLiability(assetType);
}

function isStartupEquity(assetType: AssetTypes) {
  return assetType === AssetTypes.StartupEquity;
}

const InvestmentClassTypes = {
  preferred: 'Preferred stock',
  common: 'Common stock',
  convertibleNote: 'Convertible notes',
};

function getManualAssetValue(quantity: number | null, value: number | null) {
  const qty = quantity || 0;
  const val = value || 0;

  return qty * val;
}

// Please keep logic in sync with the `getPrivateInvestmentValue` in compound-backend/src/services/assets.ts
function getRawPrivateInvestmentValue(investment: PrivateInvestment) {
  // Convertible notes have no shares, and are always values by their estimated value
  // Investments that existed prior to this field's introduction have their `committed` value
  // set as the estimatedValue
  if (
    PrivateInvestmentUtils.isConvertibleNote(investment.type) ||
    PrivateInvestmentUtils.isOtherSimpleAgreement(investment.type)
  ) {
    return investment.estimatedValue ?? 0;
  }

  if (investment.valuationType === InvestmentValuationType.LatestValuation) {
    return investment.latestValuation ?? 0;
  }

  if (investment.valuationType === InvestmentValuationType.CommittedCapital) {
    return investment.committed ?? 0;
  }

  // We always want to return the price per share when estimating values for preferred or common stock
  if (investment.valuationType === InvestmentValuationType.EstimatedValue) {
    return (investment.estimatedValue ?? 0) * (investment.shareQuantity ?? 0);
  }

  // sharePrice corresponds to the latest preferred price / 409a
  if (investment.valuationType === InvestmentValuationType.LatestPrice) {
    return (investment.sharePrice ?? 0) * (investment.shareQuantity ?? 0);
  }

  // If the user values by the original price, just return the initial committed amount of capital
  return investment.committed ?? 0;
}

function getPrivateInvestmentValue(investment: PrivateInvestment) {
  const rawValue = getRawPrivateInvestmentValue(investment);

  const ownershipPercentage =
    OwnershipAdjustmentUtils.getTotalOwnershipRatio(investment);

  // Adjust value by ownership
  return rawValue * ownershipPercentage;
}

/**
 * Helper function to get the value of an asset according to the property that
 * represents the current balance of that asset.
 *
 * For example, the RealEstate asset type holds its balance in the `value` property,
 * whereas a PrivateInvestment's value is derived from the `sharePrice` * `shareQuantity`.
 *
 * NOTE: This function is called in account snapshot normalization code, and therefore
 * does not transform liability values into negative numbers. If a liability's balance
 * needs to be displayed, it should be transformed into a negative at the lowest
 * possible component level
 *
 * @param asset
 */
function getAssetValue(asset: CompoundAsset) {
  switch (asset.assetType) {
    case AssetTypes.Other:
      const otherAsset = asset as OtherAsset;
      return getManualAssetValue(otherAsset.quantity, otherAsset.value);
    case AssetTypes.RealEstate:
      return (asset as RealEstate).value || 0;
    case AssetTypes.PrivateInvestment:
      const privateInvestment = asset as PrivateInvestment;
      if (privateInvestment.cancelled) {
        return 0;
      }
      return getPrivateInvestmentValue(privateInvestment);

    case AssetTypes.LP:
    case AssetTypes.GP:
      return (asset as FundInvestment).currentBalance || 0;
    case AssetTypes.Loan:
    case AssetTypes.OtherLiability:
      return Math.abs((asset as Loan).currentBalance || 0);
    default:
      return 0;
  }
}

/**
 * Like `getAssetValue`, but returns the ownership de-adjusted value instead of the ownership-adjusted value.
 * @param asset
 * @returns
 */
function getActualAssetValue(asset: CompoundAsset) {
  if (asset.assetType === AssetTypes.PrivateInvestment) {
    return getRawPrivateInvestmentValue(asset as PrivateInvestment);
  }
  return getAssetValue(OwnershipAdjustmentUtils.deAdjustCompoundAsset(asset));
}

type NamedAsset<T = {}> = T & {
  companyName?: string | null;
  name?: string | null;
  fundName?: string | null;
  label?: string | null;
  assetType?: AssetTypes;
};

function getAssetName(asset: NamedAsset): string {
  const { assetType } = asset;

  const companyName = get(asset, 'companyName');

  const name = asset.name || asset.fundName;

  if (companyName) {
    return companyName as string;
  }

  if (assetType === AssetTypes.LP || assetType === AssetTypes.GP) {
    // TODO(wuharvey): Migrate all emdashes to 'N/A' so this logic can be simpler.
    if (
      (asset as FundInvestment).fundName &&
      (asset as FundInvestment).fundName !== '-'
    ) {
      const fundName = (asset as FundInvestment).fundName;
      if ((asset as LPExposure)?.provider === IntegrationType.Contro) {
        return fundName;
      }
      let firmName = (asset as FundInvestment).firmName || 'N/A';
      if (firmName === '-') {
        firmName = 'N/A';
      }

      return `${firmName} - ${fundName}`;
    }

    return (asset as FundInvestment).firmName || asset.name || '';
  }

  if (assetType === AssetTypes.OtherLiability) {
    return (asset as NamedAsset<Loan>).officialName ?? name;
  }

  if (assetType === AssetTypes.PrivateInvestment) {
    const pi = asset as NamedAsset<PrivateInvestment>;
    // We have to make this clunky distinction because this asset is normalized to fit
    // the props expected in the account page table rows when called in that context.
    // This function is also called elsewhere, where the asset shape will conform to the
    // PrivateInvestment type
    return `${pi.companyName ?? name} — ${AssetUtils.getInvestmentClass(pi)}`;
  }

  const isConnectedPlaidAccount =
    has(asset, 'subtype') && !isNil(asset['institution']);
  const isCrypto = PlaidAccountSharedUtils.isCrypto(asset as PlaidAccount);

  if (isConnectedPlaidAccount && isCrypto) {
    return PlaidAccountUtils.getAccountName(asset as PlaidAccount);
  }

  return (asset.label || name) as string;
}

const getAssetSource = (asset: CompoundAsset): string => {
  const isBankAccount = AccountPageUtils.isPlaidAccount(asset);
  if (isBankAccount) {
    const account = asset as PlaidAccount;
    if (PlaidAccountUtils.isSelfServeProvider(account)) {
      return AssetAccountSectionTypes.Manual;
    }
    if (account.provider === PlaidAccountProvider.BlackDiamond) {
      return AssetAccountSectionTypes.Schwab;
    }

    return account.provider;
  }
  if (asset.assetType === AssetTypes.PrivateInvestment) {
    return (asset as PrivateInvestment).provider as string;
  }
  return AssetAccountSectionTypes.Manual;
};

const getCompoundInvestmentImage = (asset: PlaidAccount) => {
  let name = InstitutionName.Compound;
  if (isCompoundIntelligentPortfolio(asset)) {
    name = InstitutionName.CompoundIntelligentInvest;
  }

  return getInstitutionImage(asset, name);
};

/**
 * Utility function to get the underlying image for the institution backing
 * this particular asset account.
 *
 * First, checks for the existence of a custom image that we have defined.
 * If one is not found, the function will attempt to return the value of the logo property
 * of the account's institution.
 *
 * If a logo has been defined, returns a string that can be provided to the `src` property
 * of an <img> node.
 *
 * @param asset
 * @returns string | null
 */
const getPlaidAssetImage = (asset: PlaidAccount) => {
  /**
   * Most accounts are represented by their underlying institution's image,
   * e.g. a Chase checking account will have an image of the Chase logo.
   *
   * Our Compound investment portfolio, however, has a custom image, unrelated to
   * the underlying institution.
   *
   * So, we check first for the existence of a black diamond integration, and if found,
   * we explicitly pass the name of the institution for which we want to retrieve an image
   * */
  const customInstitutionImage =
    asset.provider === PlaidAccountProvider.BlackDiamond ||
    asset.provider === PlaidAccountProvider.Orion
      ? getCompoundInvestmentImage(asset)
      : getInstitutionImage(asset);

  if (customInstitutionImage) {
    return customInstitutionImage;
  }

  const maybeLogo = asset.institution?.logo || null;

  // Not all institutions have logos, and base64ing undefined produces a broken image
  if (!maybeLogo) {
    return null;
  }

  // Some logos are pngs on CDNs, so we need to check for a url
  if (URL_REGEX.test(maybeLogo)) {
    return maybeLogo;
  }

  return `data:image/jpeg;base64,${maybeLogo}`;
};

function getIsPlaidAccount(account: ImageableAsset) {
  return has(account, 'institution') || has(account, 'institutionId');
}

function getIsPeAccount(account: ImageableAsset) {
  return (
    has(account, 'relationship') || get(account, 'type') === AccountType.Equity
  );
}

function getIsPrivateInvestment(account: ImageableAsset) {
  const asset = account as AssetFormValues<PrivateInvestment>;
  return (
    asset.assetType === AssetTypes.PrivateInvestment ||
    (asset.type && Object.values(PrivateInvestmentType).includes(asset.type))
  );
}

function getAssetColor(account: ImageableAsset) {
  const assetType = account.assetType;

  if (assetType) {
    return COMPOUND_COLORS[assetType as string];
  }

  if (getIsPeAccount(account)) {
    return COMPOUND_COLORS[AssetTypes.StartupEquity];
  }

  if (getIsPlaidAccount(account)) {
    return COMPOUND_COLORS[
      PlaidAccountUtils.getPlaidAssetType(account as PlaidAccount)
    ];
  }

  return null;
}

function getAssetColorByAssetType(assetType: AssetTypes) {
  if (assetType) {
    return COMPOUND_COLORS[assetType as string];
  }

  return null;
}

/**
 * Gets the default properties that we supply to the `AccountLogo` component.
 * Everything can be automatically generated. the `filteredOut` prop is set on a contextual basis
 * (depends on which filter you are looking at and contingent on corresponding accountFilters state).
 *
 * @param account — Compound asset we are fetching an image for
 */
function getDefaultAssetImageProps(account: CompoundAsset & { name?: string }) {
  const imageLogo = AssetUtils.getAssetImage(account);

  return {
    name:
      // remove numbers from real estate address because otherwise it looks weird if there is just a number in the icon
      account.assetType === AssetTypes.RealEstate
        ? account.name?.replace(/[0-9]/g, '') ?? ''
        : getAssetName(account),
    logo: imageLogo,
    color: imageLogo ? null : getAssetColor(account),
    // To be manually set by user of util
    filteredOut: undefined,
  };
}

/**
 * Determines if supplied account has an underlying banking institution,
 * and resolves the image representing that institution.
 *
 * @param asset
 * @returns Image source or null
 */
const getAssetImage = (asset: ImageableAsset) => {
  const isPlaidAccount = getIsPlaidAccount(asset);
  const isPeAccount = getIsPeAccount(asset);
  const isPrivateInvestment = getIsPrivateInvestment(asset);

  const isFundInvestment = [AssetTypes.LP, AssetTypes.GP].includes(
    asset.assetType as AssetTypes,
  );

  if (isFundInvestment) {
    return FundInvestmentUtils.getFundInvestmentAssetImage(
      (asset as FundInvestment).firmName || (asset as FundInvestment).name,
    );
  }

  if (isPeAccount || isPrivateInvestment) {
    return getCompanyImage(getAssetName(asset));
  }

  if (!isPlaidAccount) {
    return null;
  }

  const account = asset as PlaidAccount;

  return getPlaidAssetImage(account);
};

function getInvestmentClass(asset: PrivateInvestment) {
  return InvestmentClassTypes[asset.type as string];
}

function getPeAccounts(state: ApplicationRootState) {
  return state.assets.privateEquityAccounts;
}

function getTaxProfiles(state: ApplicationRootState) {
  return state.global.taxProfiles;
}

function getPlaidAccounts(state: ApplicationRootState) {
  return state.assets.accounts;
}

function getAccounts(state: ApplicationRootState) {
  const assetsState = state.assets;

  return pick(
    assetsState,
    AssetTypes.Other,
    AssetTypes.RealEstate,
    AssetTypes.PrivateInvestment,
    AssetTypes.LP,
    AssetTypes.GP,
    AssetTypes.Loan,
    AssetTypes.OtherLiability,
  );
}

const flattenAssetsAndAssignAssetType = (assetsMap: any) =>
  reduce(
    assetsMap,
    (memo, value, key) => {
      const assetsWithAssetType = (value as CompoundAsset[]).map(
        (a: CompoundAsset) => ({
          ...a,
          assetType: key,
        }),
      );

      return memo.concat(assetsWithAssetType as CompoundAsset[]);
    },
    [] as CompoundAsset[],
  );

/**
 * Selector which flattens all accounts of the asset types we support,
 * and decorates the accounts with its corresponding assetType
 **/
const getAllCompoundAccounts = createSelector([getAccounts], (assetsMap) =>
  flattenAssetsAndAssignAssetType(assetsMap),
);

export type CompoundAssetsMap = Record<
  AssetTypes | FileEntityTypes,
  Array<CompoundAsset | PrivateEquityAccount | PlaidAccount | TaxProfile>
>;

/**
 * Selector that organizes compound Assets by type.
 * Transforms all manual assets, assets linked via carta, and private equity accounts into
 * a map of assets keyed to their AssetType
 */
const getCompoundAssets = createSelector(
  [getAccounts, getPeAccounts, getTaxProfiles, getPlaidAccounts],
  (assetsMap, peAccounts, taxProfiles, plaidAccounts) => {
    const groupedPlaidAccounts =
      PlaidAccountSharedUtils.groupByAccountType(plaidAccounts);
    const base = {
      [FileEntityTypes.PrivateEquityAccount]: peAccounts,
      [FileEntityTypes.Tax]: taxProfiles,
      ...groupedPlaidAccounts,
    } as CompoundAssetsMap;

    return reduce(
      assetsMap,
      (assetsMap, assetsList, assetType: AssetTypes) => {
        // Return sorted private investments by CO Name, otherwise return default list (organized by createdAt)
        const sortedAssetList =
          assetType === AssetTypes.PrivateInvestment
            ? sortBy(assetsList, 'companyName')
            : assetsList;
        const mappedAssetTypes = (sortedAssetList as CompoundAsset[]).map(
          (a: CompoundAsset) =>
            ({
              ...a,
              assetType,
            } as CompoundAsset),
        );
        const convertedAssetType =
          assetType === AssetTypes.Loan ? AssetTypes.OtherLiability : assetType;

        return {
          ...assetsMap,
          [convertedAssetType]: mappedAssetTypes,
        };
      },
      base,
    );
  },
);

const AssetUtils = {
  isLiability,
  isAsset,
  isStartupEquity,
  getAssetValue,
  getActualAssetValue,
  getAssetName,
  getInvestmentClass,
  getAssetSource,
  getAssetImage,
  getDefaultAssetImageProps,
  getAssetColor,
  getAssetColorByAssetType,
  getPlaidAssetImage,
  getPrivateInvestmentValue,
  getAllCompoundAccounts,
  useGetCompoundAssets() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useTypedSelector<CompoundAssetsMap>(getCompoundAssets, isEqual);
  },
  useFlattenCompoundAssets(includeHiddenAccounts = false): CompoundAsset[] {
    const rawPrivateInvestments = useTypedSelector(
      (state) => state.assets.rawPrivateInvestment,
      isEqual,
    );
    const allAssets: CompoundAsset[] = useTypedSelector(
      getAllCompoundAccounts,
      isEqual,
    );

    const assets = useMemo(() => {
      let assets = allAssets;
      if (includeHiddenAccounts) {
        const assetIds = assets.map((a) => a.id);
        assets = assets.concat(
          rawPrivateInvestments
            .filter((a) => !assetIds.includes(a.id))
            .map((a) => ({ ...a, assetType: AssetTypes.PrivateInvestment })),
        );
      }
      return assets;
    }, [allAssets, rawPrivateInvestments, includeHiddenAccounts]);

    return assets;
  },
  flattenAssetsAndAssignAssetType,
};

export default AssetUtils;
