import {
  EquityHoldingTypes,
  PrivateEquityAccount,
} from '@compoundfinance/compound-core/dist/types/equity';
import { AssetTitles } from 'components/EquityManagement/Asset/constants';
import { AssetTypes } from '@compoundfinance/compound-core/dist/types/account';
import directEquityBreakdown from 'shared/equity/directEquityBreakdown';
import { AccountSectionItem } from 'types/assets/section';
import { isInvestorAccount } from 'utils/peAccount';
import { AssetsDisplayOrder } from 'utils/constants/assets';
import DirectEquityBreakdownPresenter from 'shared/equity/directEquity';
import { buildAssetAccountSection } from 'utils/presenters/accountSections';
import { AssetSubType, FilterType } from 'utils/assetFilters/types';
import { createCompanyKey, filterAccounts } from 'utils/assetFilters/utils';

export enum AccountSectionTranche {
  CommonStock = 'commonStock',
  Vested = 'vested',
  UnvestedRSU = 'unvestedRsu',
  UnvestedOption = 'unvestedOption',
  EarlyExercisedUnvested = 'earlyExerciseUnvested',
}

export const assetSubTypeToAccountSectionTranche = (
  assetSubType: AssetSubType,
): AccountSectionTranche | undefined => {
  switch (assetSubType) {
    case AssetSubType.Common:
      return AccountSectionTranche.CommonStock;
    case AssetSubType.EarlyExerciseUnvested:
      return AccountSectionTranche.EarlyExercisedUnvested;
    case AssetSubType.UnvestedOptions:
      return AccountSectionTranche.UnvestedOption;
    case AssetSubType.UnvestedRSU:
      return AccountSectionTranche.UnvestedRSU;
    case AssetSubType.VestedOptions:
      return AccountSectionTranche.Vested;
    case AssetSubType.Company:
    case AssetSubType.Convertible:
    case AssetSubType.EarlyExerciseRSA:
      return undefined;
  }
};

/**
 * Gets equitySection accounts and privateEquityAccounts and returns a list of accounts
 * that were in privateEquityAccount but not in equitySection accounts.
 * Used to show "Add Equity" button.
 * @param accounts
 * @param privateEquityAccounts
 * @returns
 */
const getCompaniesWithNoGrants = (
  accounts: AccountSectionItem[],
  privateEquityAccounts: PrivateEquityAccount[],
): AccountSectionItem[] => {
  const companiesWithoutGrants = privateEquityAccounts.filter(
    ({ id }) => !accounts.some((account) => account.id === id),
  );
  const emptyCompanySections = companiesWithoutGrants.map((company) => ({
    ...company,
    accountId: company.id,
    key: `${company.id}-${AssetSubType.Company}`,
    name: company.companyName,
    currentBalance: 0,
    assetType: AssetTypes.StartupEquity,
  }));
  return emptyCompanySections;
};

/**
 * From a list of private equity accounts, generates an AssetAccountSection object
 * with a flat list of 'accounts' with the total value of the following tranches of equity:
 *
 * vested options
 * unvested rsus/rsas
 * unvested options
 * early exercised options
 * common stock
 * preferred stock
 * convertible notes
 *
 *
 * @param peAccounts
 * @param filterMap
 */
const getEquityAccountSection = (
  peAccounts: PrivateEquityAccount[],
  filterMap?: Record<string, FilterType | boolean>,
  vestByDate?: Date,
  includeHiddenTranches: boolean = false,
  includeGrantlessPrivateEquityAccounts: boolean = false,
) => {
  let { equityAccounts, notes, investorEquity } = splitEquityAccounts(
    peAccounts,
    vestByDate,
  );

  const visibleEquityAccounts = includeHiddenTranches
    ? equityAccounts
    : filterAccounts(equityAccounts, filterMap || {}, FilterType.Hide);
  const filtered = filterMap
    ? visibleEquityAccounts
        .filter(
          (account) =>
            filterMap[account.key as string] ||
            filterMap[createCompanyKey(account.key as string)],
        )
        .map((a) => a.key as string)
    : [];

  const equitySection = buildAssetAccountSection({
    title: AssetTitles[AssetTypes.StartupEquity],
    accounts: [
      ...visibleEquityAccounts,
      ...(includeGrantlessPrivateEquityAccounts
        ? getCompaniesWithNoGrants(equityAccounts, peAccounts)
        : []),
    ],
    type: AssetTypes.StartupEquity,
    displayOrder: AssetsDisplayOrder[AssetTypes.StartupEquity],
    filtered,
    filterKey: 'key',
  });

  return {
    equitySection,
    convertibleNotes: includeHiddenTranches
      ? notes
      : filterAccounts<AccountSectionItem>(
          notes,
          filterMap || {},
          FilterType.Hide,
        ),
    investorEquity: includeHiddenTranches
      ? investorEquity
      : filterAccounts<AccountSectionItem>(
          investorEquity,
          filterMap || {},
          FilterType.Hide,
        ),
  };
};

const splitEquityAccounts = (
  peAccounts: PrivateEquityAccount[],
  vestByDate?: Date,
) => {
  const notes: AccountSectionItem[] = [];
  const investorEquity: AccountSectionItem[] = [];
  const equityAccounts = peAccounts.flatMap((account) => {
    const breakdown = directEquityBreakdown(account, vestByDate);
    const {
      commonStockTotal,
      commonStockCount,
      availableVestedTotal,
      availableVestedCount,
      convertibleNotesCount,
      convertibleNotesTotal,
    } = breakdown;

    const directEquityPresenter = new DirectEquityBreakdownPresenter(breakdown);

    const rsaElectionCount = directEquityPresenter.getEarlyExercised(
      EquityHoldingTypes.RSA,
      'count',
    );
    const earlyExercisedUnvestedOptionCount =
      directEquityPresenter.getEarlyExercised(
        EquityHoldingTypes.Option,
        'count',
      );

    const earlyExercisedUnvestedOptionTotal =
      directEquityPresenter.getEarlyExercised(
        EquityHoldingTypes.Option,
        'value',
      );

    const rsaElectionTotal = directEquityPresenter.getEarlyExercised(
      EquityHoldingTypes.RSA,
      'value',
    );

    const unvestedUnexercisedOptionTotal =
      directEquityPresenter.getUnvested(EquityHoldingTypes.Option, 'value') -
      earlyExercisedUnvestedOptionTotal;

    const unvestedUnexercisedOptionCount =
      directEquityPresenter.getUnvested(EquityHoldingTypes.Option, 'count') -
      earlyExercisedUnvestedOptionCount;

    const unvestedRsuCount = directEquityPresenter.getUnvested(
      EquityHoldingTypes.RSU,
      'count',
    );
    const unvestedRsuTotal = directEquityPresenter.getUnvested(
      EquityHoldingTypes.RSU,
      'value',
    );

    const investor = isInvestorAccount(account);

    let splitAccounts: AccountSectionItem[] = [];

    if (commonStockCount) {
      const common = {
        ...account,
        id: account.id,
        /**
         * If `brokerageAdded`, the equity has gone public, so there's a matching `Public Assets` account too.
         * We force the `currentBalance` to be 0 to avoid taking the value into account twice.
         */
        currentBalance: account.brokerageAdded ? 0 : commonStockTotal,
        name: `${account.companyName} · Common stock`,
        type: 'stock',
        tranche: AccountSectionTranche.CommonStock,
        key: `${account.id}-${AssetSubType.Common}`,
      };
      investor ? investorEquity.push(common) : splitAccounts.push(common);
    }

    if (availableVestedCount) {
      splitAccounts.push({
        ...account,
        id: account.id,
        currentBalance: Math.max(availableVestedTotal, 0),
        name: `${account.companyName} · Vested options`,
        type: 'equity',
        tranche: AccountSectionTranche.Vested,
        key: `${account.id}-${AssetSubType.VestedOptions}`,
      });
    }

    if (unvestedUnexercisedOptionCount) {
      splitAccounts.push({
        ...account,
        id: account.id,
        currentBalance: Math.max(unvestedUnexercisedOptionTotal, 0),
        name: `${account.companyName} · Unvested options`,
        type: 'equity',
        tranche: AccountSectionTranche.UnvestedOption,
        key: `${account.id}-${AssetSubType.UnvestedOptions}`,
      });
    }

    if (unvestedRsuCount) {
      splitAccounts.push({
        ...account,
        id: account.id,
        currentBalance: Math.max(unvestedRsuTotal, 0),
        name: `${account.companyName} · Unvested RSUs`,
        type: 'equity',
        tranche: AccountSectionTranche.UnvestedRSU,
        key: `${account.id}-${AssetSubType.UnvestedRSU}`,
      });
    }

    if (earlyExercisedUnvestedOptionCount) {
      splitAccounts.push({
        ...account,
        id: account.id,
        currentBalance: Math.max(earlyExercisedUnvestedOptionTotal, 0),
        name: `${account.companyName} · Early exercised unvested options`,
        type: 'equity',
        tranche: AccountSectionTranche.EarlyExercisedUnvested,
        key: `${account.id}-${AssetSubType.EarlyExerciseUnvested}`,
      });
    }

    if (rsaElectionCount) {
      splitAccounts.push({
        ...account,
        id: account.id,
        currentBalance: Math.max(rsaElectionTotal, 0),
        name: `${account.companyName} · 83(b) Election RSAs`,
        type: 'equity',
        tranche: AccountSectionTranche.EarlyExercisedUnvested,
        key: `${account.id}-${AssetSubType.EarlyExerciseRSA}`,
      });
    }

    if (convertibleNotesCount) {
      notes.push({
        ...account,
        id: account.id,
        currentBalance: convertibleNotesTotal,
        name: `${account.companyName} · Convertible notes`,
        key: `${account.id}-${AssetSubType.Convertible}`,
      });
    }

    return splitAccounts.map((account) => ({
      ...account,
      assetType: AssetTypes.StartupEquity,
    }));
  });

  return { equityAccounts, notes, investorEquity };
};

const AccountsViewUtils = {
  getEquityAccountSection,
  getCompaniesWithNoGrants,
  splitEquityAccounts,
};

export default AccountsViewUtils;
