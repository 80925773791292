import { CompoundFile } from 'types/file';
import { action } from 'typesafe-actions';

export enum actionTypes {
  // upload
  StartUpload = 'Upload/StartUpload',
  ResolveUpload = 'Upload/ResolveUpload',
  RejectUpload = 'Upload/RejectUpload',
  // delete
  StartDelete = 'Upload/StartDelete',
  ResolveDelete = 'Upload/ResolveDelete',
  RejectDelete = 'Upload/RejectDelete',
  // other
  ResetState = 'Upload/ResetState',
}

export const fileActions = {
  // upload
  startUpload(file: File) {
    return action(actionTypes.StartUpload, { file });
  },
  resolveUpload(file: File | CompoundFile) {
    return action(actionTypes.ResolveUpload, { file });
  },
  rejectUpload(file: File) {
    return action(actionTypes.RejectUpload, { file });
  },
  // delete
  startDelete() {
    return action(actionTypes.StartDelete);
  },
  resolveDelete() {
    return action(actionTypes.ResolveDelete);
  },
  rejectDelete() {
    return action(actionTypes.RejectDelete);
  },
  // other
  resetState() {
    return action(actionTypes.ResetState);
  },
};
