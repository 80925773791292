import _ from 'lodash';

import { FundInvestmentTypeLabels } from 'components/EquityManagement/Asset/Form/FundInvestment/constants';
import {
  FundInvestment,
  FundInvestmentType,
  LPExposure,
  LPExposureEventType,
  GPExposure,
} from 'types/fundInvestments';
import { AssetTypes } from '@compoundfinance/compound-core/dist/types/account';

import eightVCLogo from 'assets/images/institutions/8vc.webp';
import accelLogo from 'assets/images/institutions/accel.webp';
import accelerateLogo from 'assets/images/institutions/accelerate.webp';
import andreessenHorowitzLogo from 'assets/images/institutions/a16z.webp';
import benchmarkLogo from 'assets/images/institutions/benchmark.webp';
import bessemerLogo from 'assets/images/institutions/bessemer.webp';
import bondLogo from 'assets/images/institutions/bond.webp';
import crecLogo from 'assets/images/institutions/crec.webp';
import dstLogo from 'assets/images/institutions/dst.webp';
import indexVenturesLogo from 'assets/images/institutions/index-ventures.webp';
import firstRoundLogo from 'assets/images/institutions/first-round.webp';
import foundersFundLogo from 'assets/images/institutions/founders-fund.webp';
import generalCatalystLogo from 'assets/images/institutions/general-catalyst.webp';
import greycroftLogo from 'assets/images/institutions/greycroft.webp';
import greylockLogo from 'assets/images/institutions/greylock.webp';
import invitoEpLogo from 'assets/images/institutions/invito-ep.webp';
import kleinerPerkinsLogo from 'assets/images/institutions/kleiner-perkins.webp';
import khoslaVenturesLogo from 'assets/images/institutions/khosla-ventures.webp';
import legalistLogo from 'assets/images/institutions/legalist.webp';
import lhgLogo from 'assets/images/institutions/lhg.webp';
import lightspeedLogo from 'assets/images/institutions/lightspeed.webp';
import menloVenturesLogo from 'assets/images/institutions/menlo-ventures.webp';
import neaLogo from 'assets/images/institutions/nea.webp';
import sequoiaLogo from 'assets/images/institutions/sequoia.webp';
import thriveLogo from 'assets/images/institutions/thrive.webp';
import lachyGroomLogo from 'assets/images/institutions/lachy-groom.jpg';
import blackstoneLogo from 'assets/images/institutions/blackstone.webp';
import btgLogo from 'assets/images/institutions/btg.webp';
import bridgeLogo from 'assets/images/institutions/bridge.webp';
import icapitalLogo from 'assets/images/institutions/icapital.webp';
import goodwaterLogo from 'assets/images/institutions/goodwater.webp';
import { getEnumValues } from 'utils/constants';

enum FundInstitutions {
  EightVC = '8VC',
  A16z = 'a16z',
  Accel = 'accel',
  Accelerate = 'accelerate',
  AndreessenHorowitz = 'andreessen horowitz',
  Benchmark = 'benchmark',
  Bessemer = 'bessemer',
  Bond = 'bond',
  Crec = 'crec',
  Dst = 'dst',
  FirstRound = 'first round',
  FoundersFund = 'founders fund',
  GeneralCatalyst = 'general catalyst',
  Greycroft = 'greycroft',
  Greylock = 'greylock',
  IndexVentures = 'index ventures',
  InvitoEP = 'invitoep',
  KleinerPerkins = 'kleiner perkins',
  KhoslaVentures = 'khosla ventures',
  LachyGroom = 'lachy groom',
  Legalist = 'legalist',
  Lgf = 'lgf',
  Lhg = 'lhg',
  Lightspeed = 'lightspeed',
  LSVP = 'lsvp', // Lightspeed Venture Partners alias
  MenloVentures = 'menlo ventures',
  Nea = 'nea',
  Sequoia = 'sequoia',
  Thrive = 'thrive',
  Blackstone = 'blackstone',
  Bridge = 'bridge',
  BTG = 'btg',
  ICapital = 'icapital',
  GoodWater = 'goodwater',
}

const FundInstitutionLogos = {
  [FundInstitutions.EightVC]: eightVCLogo,
  [FundInstitutions.A16z]: andreessenHorowitzLogo,
  [FundInstitutions.Accel]: accelLogo,
  [FundInstitutions.Accelerate]: accelerateLogo,
  [FundInstitutions.AndreessenHorowitz]: andreessenHorowitzLogo,
  [FundInstitutions.Benchmark]: benchmarkLogo,
  [FundInstitutions.Bessemer]: bessemerLogo,
  [FundInstitutions.Bond]: bondLogo,
  [FundInstitutions.Crec]: crecLogo,
  [FundInstitutions.Dst]: dstLogo,
  [FundInstitutions.FirstRound]: firstRoundLogo,
  [FundInstitutions.FoundersFund]: foundersFundLogo,
  [FundInstitutions.GeneralCatalyst]: generalCatalystLogo,
  [FundInstitutions.Greycroft]: greycroftLogo,
  [FundInstitutions.Greylock]: greylockLogo,
  [FundInstitutions.IndexVentures]: indexVenturesLogo,
  [FundInstitutions.InvitoEP]: invitoEpLogo,
  [FundInstitutions.KleinerPerkins]: kleinerPerkinsLogo,
  [FundInstitutions.KhoslaVentures]: khoslaVenturesLogo,
  [FundInstitutions.LachyGroom]: lachyGroomLogo,
  [FundInstitutions.Legalist]: legalistLogo,
  [FundInstitutions.Lgf]: lachyGroomLogo,
  [FundInstitutions.Lhg]: lhgLogo,
  [FundInstitutions.Lightspeed]: lightspeedLogo,
  [FundInstitutions.LSVP]: lightspeedLogo,
  [FundInstitutions.MenloVentures]: menloVenturesLogo,
  [FundInstitutions.Nea]: neaLogo,
  [FundInstitutions.Sequoia]: sequoiaLogo,
  [FundInstitutions.Thrive]: thriveLogo,
  [FundInstitutions.Blackstone]: blackstoneLogo,
  [FundInstitutions.Bridge]: bridgeLogo,
  [FundInstitutions.BTG]: btgLogo,
  [FundInstitutions.ICapital]: icapitalLogo,
  [FundInstitutions.GoodWater]: goodwaterLogo,
};

const fundInstitutionLogosList = getEnumValues(FundInstitutions);

/**
 * Utility function to get the underlying image for the institution backing
 * this particular fund investment asset.
 *
 * The function will attempt to match the name of the fund investment to an institution's name.
 *
 * If a logo has been defined, returns a string that can be provided to the `src` property
 * of an <img> node.
 *
 * @param asset
 * @returns string | null
 */
function getFundInvestmentAssetImage(fundInvestmentName: string) {
  const institutionKey = fundInstitutionLogosList.find((institution) => {
    // Check if institution is part of fundInvestmentName. Case insensitive. Don't match cases where a word partially matches institution name, e.g., "chairbnb" shouldn't match "airbnb", but "ch airbnb" should.
    const regex = new RegExp(`(\\s|-|^)${institution}(\\s|-|$)`, 'i');
    return regex.test(fundInvestmentName);
  });

  const logo = FundInstitutionLogos[institutionKey ?? ''];

  if (!logo) {
    return null;
  }

  return logo;
}

function getFundInvestmentTypeLabel(type: FundInvestmentType) {
  const types = Object.entries(FundInvestmentType);
  const [key] = types.find(([, value]) => value === type) ?? [''];

  return FundInvestmentTypeLabels[key];
}

function getIsFundInvestment(account: any): account is FundInvestment {
  return [AssetTypes.GP, AssetTypes.LP].includes(account?.assetType);
}

function getCapitalByType(lpExposure: LPExposure, type: LPExposureEventType) {
  const eventsByType = lpExposure?.events?.filter((e) => e.type === type) ?? [];
  return _.sumBy(eventsByType, 'amount');
}

function getDistributedCapital(lpExposure: LPExposure) {
  return getCapitalByType(lpExposure, LPExposureEventType.Distribution);
}

function getCalledCapital(lpExposure: LPExposure) {
  return getCapitalByType(lpExposure, LPExposureEventType.CapitalCall);
}

function getUncalledCapital(lpExposure: LPExposure) {
  const committedCapital = lpExposure?.committedCapital ?? 0;
  return committedCapital - getCalledCapital(lpExposure);
}

function getLPExposures(fundInvestments: FundInvestment[]) {
  return fundInvestments.filter(
    (i) => i.assetType === AssetTypes.LP,
  ) as LPExposure[];
}

function getGPExposures(fundInvestments: FundInvestment[]) {
  return fundInvestments.filter(
    (i) => i.assetType === AssetTypes.GP,
  ) as GPExposure[];
}

const FundInvestmentUtils = {
  getFundInvestmentAssetImage,
  getFundInvestmentTypeLabel,
  getIsFundInvestment,
  getDistributedCapital,
  getCalledCapital,
  getUncalledCapital,
  getLPExposures,
  getGPExposures,
};

export default FundInvestmentUtils;
