import { AssetTypes } from '@compoundfinance/compound-core/dist/types/account';
import { OrganizerIntakeStatus } from 'containers/Dashboard/PartnerPortal/types';
import { Address } from 'types/address';

export enum EstateRelationshipTypes {
  Married = 'married',
  TwoYears = 'marriedInTwoYears',
  Single = 'single',
  Divorced = 'divorced',
  Widowed = 'widowed',
}

export enum UserRelationshipTypes {
  Spouse = 'spouse',
  FormerSpouse = 'formerSpouse',
  Parent = 'parent',
  Child = 'child',
  Self = 'self',
  TrustedContact = 'trustedContact',
  JointOwner = 'jointOwner',
  Friend = 'friend',
  Sibling = 'sibling',
  Partner = 'partner',
  Other = 'other',
}

export enum CitizenTypes {
  Citizen = 'citizen',
  PermanentResident = 'permanentResident',
  NonPermanentResident = 'nonPermanentResident',
  Other = 'otherCitizenship',
  DualCitizen = 'dualCitizen',
}

export interface LegalPerson {
  id?: string;
  legalFirstName: string | null;
  legalMiddleName?: string | null;
  legalLastName: string | null;
  birthday: Date | null;
  residentState: string | null;
  citizenship: CitizenTypes | null;
  userRelationship: UserRelationshipTypes;
  relationshipStatus: EstateRelationshipTypes | null;
  deceased: boolean | null;
  phoneNumber: string | null;
  residency: string | null;
  address: Address | undefined;
  userId?: string;
  email?: string | null;
  employmentStatus: string | null;
  preferredFirstName?: string | null;
  preferredLastName?: string | null;
  proofOfAddress?: string | null; // vault file id
  entityUserId?: string | null; // optional shared user id
  retirementAge?: number | null;
}

export interface Spouse extends LegalPerson {
  preOrPostNuptialAgreement?: boolean | null;
}

export interface PriorSpouse extends LegalPerson {
  // Only applies to former spouses
  continuingObligation?: boolean | null;
}

export interface PersonalInformation extends LegalPerson {
  otherResidence: string;
  otherCitizenship: string;
  expectedIncome: number | null;
  hasOtherResidence: boolean | null;
  hasOtherCitizenship: boolean | null;
}

export interface FamilyAndFriends {
  spouse: Spouse;
  priorSpouses: Spouse[];
  parents: LegalPerson[];
  children: LegalPerson[];
  trustedParties: LegalPerson[];
  hasChildren: boolean | null;
  hasTrustedParties: boolean | null;
  hasPriorSpouses: boolean | null;
}

export interface EstateDetails {
  hasWill: boolean | null;
  hasTrust: boolean | null;
  hasLifeInsurance: boolean | null;
  trustInfo: string | null;
  inheritanceInfo: string | null;
  charitableGivingInfo: string | null;
  lifeInsuranceInfo: string | null;
}

export interface EstateFinanceItem {
  ownerId: string;
  ownerName: string;
  included: boolean;
  percent: number;
}
export interface EstateFinanceDatum {
  assetType: AssetTypes;
  color: string;
  currentBalance: number;
  logo: string | null;
  name: string;
  ownerships: EstateFinanceItem[];
}

export interface EstateFinances {
  [key: string]: EstateFinanceDatum;
}

/**
 * Specific type to model the friends and family response we
 * get back from the server
 */
export type RawFamilyFriends = Omit<
  FamilyAndFriends,
  'hasPriorSpouses' | 'hasChildren' | 'hasTrustedParties'
>;
export interface EstateProfile {
  id?: string;
  userId: string;
  lastWizardStep: number;
  personalInformation: PersonalInformation;
  familyAndFriends: FamilyAndFriends;
  estateDetails: EstateDetails;
  finances: EstateFinances;
  status: OrganizerIntakeStatus;
  completedAt: Date | null;
}

export interface EstateProfileRequestBody {
  hasWill: boolean | null;
  hasTrust: boolean | null;
  hasLifeInsurance: boolean | null;
  trustInfo: string | null;
  inheritanceInfo: string | null;
  charitableGivingInfo: string | null;
  lifeInsuranceInfo: string | null;
  finances: EstateFinances;
  lastWizardStep: number;
  userId: string;
  id?: string;
}
