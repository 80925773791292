import useTypedSelector from './typedSelector';

/**
 * Generic feature flag hook.
 *
 * Note there are special arguments for advisors. Pay careful attention to how
 * they are used if you need special behavior for advisor feature flags.
 *
 * @param name Feature flag name
 * @param advisorOverride Choose the default feature flag setting for advisors. Note if you want to use the 3rd argument, this needs to be `undefined`. If set to `false`, this function will always return
 * `false` for an advisor.
 * @returns A boolean indicating the status of the feature flag (or undefined if `cannotRerender` is true we have not yet received
 * the payload from the server)
 */
function useFeatureFlag(
  name: string,
  useAdvisorFeatureFlags?: boolean,
): boolean {
  const featureFlags = useTypedSelector((state) =>
    useAdvisorFeatureFlags
      ? state.global.advisorFeatureFlags
      : state.global.featureFlags,
  );

  // This should only happen in tests.
  if (!featureFlags) {
    return false;
  }

  return featureFlags[name] ?? false;
}

export default useFeatureFlag;
