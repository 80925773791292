export const isLocal = window.location.hostname.includes('localhost');
const isStaging = window.location.hostname.includes('staging');
const isAlpha = window.location.hostname.includes('alpha');
export const isStagingOfficial =
  window.location.hostname === 'staging.compoundplanning.com';
export const isProduction = !isLocal && !isStaging && !isAlpha;

export const STAGING = 'staging';
export const PRODUCTION = 'production';
export const DEVELOPMENT = 'development';

export function getEnvironmentName() {
  if (isLocal) {
    return DEVELOPMENT;
  }
  if (isStaging || isAlpha) {
    return STAGING;
  }
  return PRODUCTION;
}
