import { CashflowState, ContainerActions } from '../types/reducers/cashflow';
import ActionTypes from '../actionTypes/cashflow';
import {
  DEFAULT_CASHFLOW_SETTINGS,
  EMPTY_CASHFLOW,
} from 'utils/constants/cashflow';
import { replaceReducer } from 'reducers/hmr';
import { LifecycleStatus, Reducers } from 'types/reducers';
import { CashflowActivity, CashflowSettings } from 'types/cashflow';
import {
  CashflowModalType,
  CashflowSubModalType,
} from 'containers/Dashboard/Cashflow/constants';

const INITIAL_CASHFLOW_STATE: CashflowState = {
  activeCashflow: EMPTY_CASHFLOW,
  cashflows: [],
  allCashflowsRehydrated: false,
  loaded: false,
  status: LifecycleStatus.Idle,
  modalOpen: false,
  modalType: CashflowModalType.Cashflow,
  subModalOpen: false,
  subModalType: CashflowSubModalType.ActivityForm,
  isDeletingActiveCashflow: false,
  isDuplicating: false,
  curActivity: {} as CashflowActivity,
  hoverSection: null,
  simPeAccount: null,
  recacheCashflowId: null,
  cashflowSettings: DEFAULT_CASHFLOW_SETTINGS,
  scrollLeft: 0,
  monthBalanceMap: {},
};

function cashflowReducer(
  state: CashflowState = INITIAL_CASHFLOW_STATE,
  action: ContainerActions,
): CashflowState {
  switch (action.type) {
    case ActionTypes.UpdateCashflowState:
      return {
        ...state,
        ...action.payload.state,
      };
    case ActionTypes.UpdateCashflowRehydrateState:
      return {
        ...state,
        activeCashflow: {
          ...state.activeCashflow,
          rehydrated: action.payload.rehydrated,
        },
      };
    case ActionTypes.SendingRequest:
      return {
        ...state,
        status: LifecycleStatus.Pending,
      };
    case ActionTypes.ResolveRequest: {
      return {
        ...state,
        status: LifecycleStatus.Resolved,
      };
    }
    case ActionTypes.RejectRequest: {
      return {
        ...state,
        status: LifecycleStatus.Rejected,
      };
    }
    case ActionTypes.Reset: {
      return INITIAL_CASHFLOW_STATE;
    }
    case ActionTypes.UpdateCashflowSettings: {
      return {
        ...state,
        cashflowSettings: {
          ...state.cashflowSettings,
          ...action.payload.cashflowSettings,
        } as CashflowSettings,
      };
    }
    default:
      return state;
  }
}

// Replace the reducer with the hot loaded one
if (import.meta.hot) {
  import.meta.hot.accept(replaceReducer(Reducers.CASHFLOW));
}

export default cashflowReducer;
