import * as _ from 'lodash';
import { Location } from 'history';
import { User, UserSettings } from 'types/user';
import amplitude, { LogReturn, Callback } from 'amplitude-js';
import { ValueOf } from 'types';
import { matchPath } from 'react-router';
import { pathToRouteName } from 'utils/constants/url';
import { E2E_USER_DOMAIN } from './constants';

const LOCAL_DOMAINS = ['localhost', '127.0.0.1'];
const AMPLITUDE_TEST_KEY = 'c52b96bf4a4eb5dffda0fcb28d3bd71e';
const AMPLITUDE_PROXY_DOMAIN = 'amplitude.internal.withcompound.com';
const UUID_REGEX =
  /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/;

let amplitudeReady = false;

function initAmplitude(user: User, userSettings: UserSettings) {
  const fullName = `${_.capitalize(user.firstName || '')} ${_.capitalize(
    user.lastName || '',
  )}` as string;

  let API_KEY =
    LOCAL_DOMAINS.includes(window.location.hostname) ||
    user.email.endsWith(`@${E2E_USER_DOMAIN}`)
      ? AMPLITUDE_TEST_KEY
      : process.env.REACT_APP_AMPLITUDE_API_KEY;

  // https://amplitude.github.io/Amplitude-JavaScript/Options/
  amplitude.getInstance().init(API_KEY as string, user.id, {
    apiEndpoint: AMPLITUDE_PROXY_DOMAIN,
    batchEvents: true,
    eventUploadPeriodMillis: 3000,
    eventUploadThreshold: 1,
  });
  var identify = new amplitude.Identify()
    .setOnce('name', fullName)
    .set('email', user.email)
    .set('acceptedTermsOfServices', !!userSettings.acceptedTerms)
    .set('acceptedPrivacyPolicy', !!userSettings.acceptedPrivacyPolicy)
    .set('signed', userSettings.clientSigned);

  amplitude.getInstance().identify(identify);
  amplitude.getInstance().logEvent('Start');
  amplitudeReady = true;
}

export function disableAmplitude() {
  console.log('Amplitude disabled.');
  amplitudeReady = false;
}

/**
 * Please name new events using a consistent structure. The convention is present
 * tense verb + noun object. For example, "view taxes page" or "complete onboarding".
 *
 * See full event naming guide here:
 * https://docs.google.com/document/d/1Xz9WHd4jQbAvJnUnsXIqiVlmtLedijxe_ItiA0LBzLE/edit?usp=sharing
 */
export const AMPLITUDE_EVENTS = {
  HomepageAddOpen: 'open add modal on homepage',
  AddModalChooseAssetType: 'choose asset type in add asset modal',
  AssetSaveNew: 'save new asset',
  AssetSaveExisting: 'save existing asset',
  AddAssetChooseProvider: 'choose provider in add asset modal',
  AddAssetComplete: 'complete adding an asset',
  PlanningBookmarkedPlanOpen: 'open bookmarked plan',
  PlanningBookmarkedPlanCompanyStrategyOpen:
    'open bookmarked plan company strategy',
  PlanningAddCashflowPlan: 'add cashflow plan',
  PlanningMyPlansOpen: 'open my plans',
  PlanningPlanStrategyOpen: 'open plan strategy',
  PlanningPlanStrategyAdd: 'add plan strategy',
  ReportOpen: 'open report',
  OpenInvestSingleStock: 'open invest single stock',
  OpenInvestCustomBond: 'open invest custom bond',
  OpenInvestPortfolio: 'open invest portfolio',
  OpenInvestHedge: 'open invest hedge',
  OpenInvestCashGeneration: 'open cash generation',
  OpenInvestGetStarted: 'open invest get started',
  OpenBorrowGetStarted: 'open borrow get started',
  OpenBorrowBorrowAgainst: 'open borrow against',
  OpenBorrowUnlockLiquidity: 'open unlock liquidity',
  OpenBorrowBuyWithoutWait: 'open buy without wait',
  OpenBorrowHelp: 'open help',
  OpenCommandBar: 'open command bar',
  OpenVaultUploadModal: 'open vault upload modal',
  OpenAddGrantModal: 'open add grant modal',
  StartVaultUpload: 'start vault upload',
  OpenNFTModal: 'open NFT modal',
  SendReferral: 'send referral',
  OpenReferralModal: 'open referral modal',

  // Equity V2
  HaveEquityIssues: 'have equity issues',

  // Self-serve onboarding
  SplashClickBuildPortfolio: 'click build portfolio',
  YourInvestmentsTodayFinish: 'finish your investments today',
  BasicInformationUpdate: 'update basic information',
  BasicInformationFinish: 'finish basic information',
  PortfolioDesignUpdate: 'update portfolio design',
  PortfolioDesignFinish: 'finish portfolio design',
  AccountOpeningStart: 'start account opening',
  AccountOpeningUpdate: 'update account opening',
  AccountOpeningLinkBank: 'link bank account',
  AccountOpeningAttachDeposit: 'attach initial deposit',
  AccountOpeningFinish: 'finish account opening',
  SubmitDepositRequest: 'submit deposit request',

  // Product pages
  OpenRequestForPrivateWealthManagementModal:
    'open request for private wealth management modal',
  SubmitRequestForPrivateWealthManagement:
    'submit request for private wealth management',

  // Private Markets
  ClickPrivateMarketsDealGetStarted: 'click private markets deal get started',

  // Flow
  FlowCreateScenario: 'create flow scenario',
  FlowCreateStrategy: 'create flow strategy',
  FlowCreateActivity: 'create flow activity',
  FlowDeleteScenario: 'delete flow scenario',
  FlowDeleteStrategy: 'delete flow strategy',
  FlowDeleteActivity: 'delete flow activity',
  FlowApplyStrategy: 'apply flow strategy',
  FlowUnapplyStrategy: 'unapply flow strategy',
  FlowEditInflowOutflowAccounts: 'edit flow inflow outflow accounts',
  FlowChangeTimeRange: 'change flow time range',
  FlowChangeFidelity: 'change flow fidelity',
  FlowEditScenarioMetadata: 'edit flow scenario metadata',

  // Reconnection Modal
  OpenReconnectionModal: 'open reconnection modal',

  // Proposal
  ProposalPublish: 'publish proposal',
  ProposalUnpublish: 'unpublish proposal',
  ProposalCreate: 'create proposal',
  ProposalDelete: 'delete proposal',

  // Account Organizer
  AccountDetailsUpdate: 'update account details',
};

export const amplitudeLogEvent = (
  event: ValueOf<typeof AMPLITUDE_EVENTS>,
  data?: any,
  callback?: Callback,
): LogReturn | void => {
  if (amplitudeReady) {
    return amplitude.getInstance().logEvent(event, data, callback);
  }
};

export const getPageNameFromPathname = function (
  pathname: string,
  pathToRouteNameMapping: Map<string, string>,
) {
  const pathValues = [...pathToRouteNameMapping.keys()];
  const match = matchPath(pathname, {
    path: pathValues,
    exact: true,
    strict: false,
  });
  const path = match?.path;
  let compoundPageName = '';
  if (path) {
    const routeName = pathToRouteNameMapping.get(path);
    if (routeName) {
      // if there are path params we can include that in the event
      const params = match?.params || {};
      const paramValueString = path
        .split('/')
        .filter((pathComponent) => pathComponent.startsWith(':'))
        .map((pathComponent) => pathComponent.slice(1).replace(/\?$/, ''))
        .map((param) => params[param])
        .filter((paramValue) => !_.isNil(paramValue))
        .join('');
      compoundPageName = `${routeName}${paramValueString}`;
    }
  }
  return compoundPageName.replace(UUID_REGEX, '');
};

export const trackPageView = (
  location: Location,
  action: string,
): LogReturn | void => {
  // to allow for more consistent grouping if a route path changes, use the name
  // as specified in CompoundRoutes
  const compoundPageName = getPageNameFromPathname(
    location.pathname,
    pathToRouteName,
  );
  return amplitudeLogEvent(`view ${compoundPageName} page`.toLowerCase(), {
    location,
    action,
    compoundPageName,
  });
};

export default initAmplitude;
