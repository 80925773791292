import moment from 'moment';

export const TAX_RETURN_TYPES = {
  FEDERAL: 'federal',
  CA: 'ca',
  NY: 'ny',
  GENERIC_STATE: 'genericState',
};
export const NYC_INCOME_TAX_TYPE = 'nyc';
export const SF_INCOME_TAX_TYPE = 'sf';
export const CURRENT_TAX_YEAR = moment().year();
